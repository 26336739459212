export class GlobalSearchEvent extends Event {
    static NAME = "global:search";

    constructor(readonly phrase: string) {
        super(GlobalSearchEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
