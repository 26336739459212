import { makeStyles } from "../style/makeStyles";

const useStyles = makeStyles()({
    spacer: {},
    xxs: {
        height: "0.5em",
    },
    xs: {
        height: "0.75em",
    },
    sm: {
        height: "1em",
    },
    md: {
        height: "3em",
    },
    lg: {
        height: "6em",
    },
    xl: {
        height: "12em",
    },
});

const useHorizontalStyles = makeStyles()({
    spacer: {
        height: "100%",
    },
    xxs: {
        width: "0.5em",
        minWidth: "0.5em",
    },
    xs: {
        width: "0.75em",
        minWidth: "0.75em",
    },
    sm: {
        width: "1em",
        minWidth: "1em",
    },
    md: {
        width: "3em",
        minWidth: "3em",
    },
    lg: {
        width: "6em",
        minWidth: "6em",
    },
    xl: {
        width: "12em",
        minWidth: "12em",
    },
});

export interface SpacerProps {
    xxs?: true;
    xs?: true;
    sm?: true;
    md?: true;
    lg?: true;
    xl?: true;
    horizontal?: true;
}

/**
 * @deprecated There is no reason to use `<Spacer ?>`. Use margin, padding or grid/flex with `gap`
 */
export function Spacer({ xxs, xs, sm, md, lg, xl, horizontal }: SpacerProps) {
    const horizontalStyles = useHorizontalStyles();
    const verticalStyles = useStyles();
    const { classes, cx } = horizontal ? horizontalStyles : verticalStyles;

    let sizeClass = classes.md;
    if (xxs) sizeClass = classes.xxs;
    if (xs) sizeClass = classes.xs;
    if (sm) sizeClass = classes.sm;
    if (md) sizeClass = classes.md;
    if (lg) sizeClass = classes.lg;
    if (xl) sizeClass = classes.xl;

    return <div className={cx(classes.spacer, sizeClass)} />;
}
