import { Children, ForwardedRef, forwardRef } from "react";

import { ScrollableContainer, ScrollableContainerProps } from "../components/ScrollableContainer";
import { ScrollableContainerItem } from "../components/ScrollableContainerItem";

const SLIDE_MIN_WIDTH = (container: HTMLElement) => container.clientWidth * 0.7;

export type ProductsContainerSliderProps = ScrollableContainerProps;
export const ProductsContainerSlider = forwardRef(function ProductsContainerSlider(
    { children, ...props }: ProductsContainerSliderProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <ScrollableContainer slideMinWidth={SLIDE_MIN_WIDTH} variant="primary" ref={ref} {...props}>
            {Children.map(children, (node, index) => (
                <ScrollableContainerItem key={index}>{node}</ScrollableContainerItem>
            ))}
        </ScrollableContainer>
    );
});
