import { useEntityConfigurationByTypes, useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";

import { HeroImages } from "@holibob-packages/ui-core/components";

export const DEFAULT_CAROUSEL_HEIGHT = 400;

function useCarouselHeight() {
    return +useEntityConfigurationValueByKey("SETTING.CAROUSEL_HEIGHT", String(DEFAULT_CAROUSEL_HEIGHT));
}

export type HeroCarouselProps = {
    images: string[];
    children?: React.ReactNode;
    alt?: string;
};
export const HeroCarousel = ({ images, children, alt }: HeroCarouselProps) => {
    const carouselHeight = useCarouselHeight();

    return (
        <HeroImages images={images} alt={alt} mediumScreenHeight={carouselHeight}>
            {children}
        </HeroImages>
    );
};

export function ThemeHeroVideo({ children }: { children: React.ReactNode }) {
    const carouselHeight = useCarouselHeight();

    return (
        <div style={{ position: "relative", width: "100%", height: carouselHeight, maxWidth: "100vw" }}>
            <video
                autoPlay
                muted
                loop
                style={{
                    transform: "translate(0,-30px)",
                    position: "absolute",
                    zIndex: 1,
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                }}
            >
                <source src={require("assets/video/backdrop2.mp4")} />
            </video>

            <div style={{ position: "relative", zIndex: 2 }}>{children}</div>
        </div>
    );
}

export function ThemeHeroCarousel({ children }: { children: React.ReactNode }) {
    const entityVaultIds = useEntityConfigurationByTypes("CAROUSEL_IMAGE_VAULT_ID");
    const carouselImages = entityVaultIds.map(({ value }) => value);
    return <HeroCarousel images={carouselImages}>{children}</HeroCarousel>;
}

export default HeroCarousel;
