import { styled } from "@mui/material/styles";

import { Button, ButtonProps } from "./Button";

export type Tag = {
    label: string;
    value?: string;
};

type TagListProps = {
    tags?: Tag[];
    color?: ButtonProps["color"];
    onClick?: (tag: Tag) => void;
    children?: React.ReactNode;
};

const TagListContainer = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(1),
    flexWrap: "wrap",
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
    "&:hover": {
        backgroundColor: "white",
    },
    backgroundColor: "white",
    color: theme.palette.grey[600],
    whiteSpace: "nowrap",
}));

export type TagProps = ButtonProps;
export const Tag = (props: TagProps) => {
    return <ButtonStyled size="small" {...props} />;
};

export const TagList = ({ tags, color, onClick: onClickProp, children }: TagListProps) => {
    return (
        <TagListContainer>
            {tags?.map((tag, idx) => {
                const onClick = onClickProp ? () => onClickProp(tag) : undefined;
                return (
                    <Tag key={idx} color={color} onClick={onClick}>
                        {tag.label}
                    </Tag>
                );
            })}
            {children}
        </TagListContainer>
    );
};
