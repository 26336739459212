import { useSearchQuery, SearchResultFragment } from "gql";
import { useFavouriteProductCount } from "hooks/useFavouriteProducts";
import useQueryHeaders from "hooks/useQueryHeaders";
import { useNextTranslation } from "hooks/useTranslation";

interface SearchResult {
    id?: string;
    title: string | null;
    subtitle: string | null;
    type?: string;
}

const buildDefaultOptions = (
    query: string,
    favouriteProductsCount: number,
    continueTypingTitle: string,
    favouriteTitle: string,
    results?: Array<SearchResultFragment | null> | null
) => {
    const defaultOptionPrepend: Array<SearchResult> = [];
    const defaultOptionsAppend: Array<SearchResult> = [];

    if (query && query.length < 3 && !results?.length) {
        defaultOptionPrepend.push({
            title: null,
            subtitle: continueTypingTitle,
        });
    }

    if (favouriteProductsCount) {
        defaultOptionPrepend.push({
            id: "FAVOURITES",
            type: "FAVOURITES",
            title: `${favouriteTitle} (${favouriteProductsCount})`,
            subtitle: null,
        });
    }

    return { defaultOptionPrepend, defaultOptionsAppend };
};

type UseSearchOptions = {
    variables: { query: string; minCharacters?: number };
    options?: Record<string, unknown>;
    skip: boolean;
};
export const useSearch = ({ variables, options: optionsProps = {}, skip: skipProp }: UseSearchOptions) => {
    const options = useQueryHeaders(optionsProps);
    const favouriteProductsCount = useFavouriteProductCount();

    const { query, minCharacters = 3 } = variables;
    const skip = query.length < minCharacters || skipProp;
    const [t] = useNextTranslation("search");
    const continueTypingTitle = t("title.continueTyping");
    const favouriteTitle = t("title.favourites");

    const response = useSearchQuery({
        variables: {
            query,
        },
        skip,
        fetchPolicy: "no-cache",
        ssr: true,
        ...options,
    });

    const results = response.data?.search?.results;

    const { defaultOptionPrepend, defaultOptionsAppend } = buildDefaultOptions(
        query,
        favouriteProductsCount,
        continueTypingTitle,
        favouriteTitle,
        results
    );

    if (!results) {
        return {
            ...response,
            results: [...defaultOptionPrepend, ...defaultOptionsAppend],
        };
    }

    return {
        ...response,
        results: [...defaultOptionPrepend, ...results, ...defaultOptionsAppend],
    };
};

export default useSearch;
