import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CSSProperties } from "react";

export function useSectionPadding(style?: CSSProperties) {
    const theme = useTheme();
    const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
    const verticalPaddingDefault = isUpMd ? theme.spacing(6) : theme.spacing(4);

    return {
        paddingTop: style?.paddingTop ?? verticalPaddingDefault,
        paddingBottom: style?.paddingBottom ?? verticalPaddingDefault,
    };
}
