import { styled } from "@mui/material/styles";

import { urlCuration } from "@holibob-packages/url";
import { AssetUrl } from "@holibob-packages/vault";

import {
    CurationProductFragment,
    useCurationAndCurationProductsQuery,
    CurationContentItemCurationFragment,
} from "../../apiHooks/graphql";
import { useNextTranslation } from "../../hooks/useNextTranslation";
import { Link } from "../../navigation/Link";
import {
    HbmlCurationProductsHeroNode,
    HbmlCurationProductsProductHrefFactory,
    HbmlCurationProductsV2Props,
} from "../HbmlComponents";
import { Typography } from "../Typography";
import { HeroThumbnail } from "./HeroThumbnail";
import { HeroWithThumbnails } from "./HeroWithThumbnails";

const HeroWithThumbnailsCurationTitleWrapper = styled("div")({
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    gridGap: 20,
    width: "100%",
    zIndex: 1,
});

const HeroWithThumbnailsCurationTitle = styled(Typography)({
    "&&&": { fontSize: 16 },
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    alignSelf: "center",
    maxWidth: 600,
});

const SeeAllLink = styled(Link)({
    justifySelf: "end",
    display: "flex",
    alignItems: "center",
    "&&& > span": {
        textDecoration: "underline",
        lineHeight: "20px",
        fontSize: "14px",
    },
});

type HeroWithProductThumbnailsCurationProps = { curation: CurationContentItemCurationFragment };

const HeroWithProductThumbnailsCuration = (props: HeroWithProductThumbnailsCurationProps) => {
    const { curation } = props;
    const { id, name, slug } = curation;
    const [t] = useNextTranslation("hbml");
    const curationHref = urlCuration({ curationId: id, slug });

    return (
        <HeroWithThumbnailsCurationTitleWrapper>
            <HeroWithThumbnailsCurationTitle variant="label" color="light">
                {name}
            </HeroWithThumbnailsCurationTitle>
            <SeeAllLink href={curationHref}>
                <Typography size="medium" color="light" component="span">
                    {t("heroWithThumbnails.seeAll.label")}
                </Typography>
            </SeeAllLink>
        </HeroWithThumbnailsCurationTitleWrapper>
    );
};

const getProductData = (
    product: CurationProductFragment,
    productHrefFactory: HbmlCurationProductsProductHrefFactory
): HeroThumbnail => {
    const { id, slug, name, previewImage } = product;
    const productImageId = previewImage?.id ?? "";
    const href = productHrefFactory({ slug, id });
    const imageAssetUrl = AssetUrl.enforceUrlForIdOrUrl(productImageId, { type: "productImage" }).unwrap();

    return { id, name: name!, imageAssetUrl, href };
};

type HeroWithProductThumbnailsContainerProps = Omit<HbmlCurationProductsHeroNode, "mode"> & HbmlCurationProductsV2Props;

export const HeroWithProductThumbnailsContainer = (props: HeroWithProductThumbnailsContainerProps) => {
    const { parentCurationId: curationId = "", productHrefFactory, NoProductsComponent, ...restProps } = props;
    const [t] = useNextTranslation("hbml");
    const { loading, data } = useCurationAndCurationProductsQuery({
        variables: { curationId: curationId, pageSize: 5, includeProducts: true },
        skip: !curationId,
    });
    const curation = data?.hierarchyCurationList?.nodes[0];
    const products = data?.productList?.nodes ?? [];
    const thumbnails = products.map((product) => getProductData(product, productHrefFactory));

    return (
        <HeroWithThumbnails
            loading={loading}
            thumbnails={thumbnails}
            thumbnailsHeader={curation && <HeroWithProductThumbnailsCuration curation={curation} />}
            buttonLabel={t("heroWithThumbnails.seeDetails.label")}
            NoItemsComponent={NoProductsComponent}
            {...restProps}
        />
    );
};
