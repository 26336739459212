import { TypographyProps } from "@mui/material";
import { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { HeroImagesDescription } from "../HeroImages";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlHeroDescriptionNode = hbmlNodeFactory<
    typeof HbmlElements.HERO_DESCRIPTION,
    { align?: TypographyProps["align"] },
    Omit<HTMLAttributes<HTMLParagraphElement>, "color" | "content">
>({
    type: HbmlElements.HERO_DESCRIPTION,
    Component: forwardRef(function ComponentInternal({ node, ...props }, ref: ForwardedRef<HTMLElement>) {
        return <HeroImagesDescription style={{ textAlign: node.align }} ref={ref} {...props} />;
    }),
});
