import React, { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { SocialLink, SocialLinks, SocialLinkType } from "../SocialLinks";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export type HbmlSocialLink = {
    type: SocialLinkType;
    href: string;
};
export const HbmlSocialLinksNode = hbmlNodeFactory<
    typeof HbmlElements.SOCIAL_LINKS,
    {
        links?: HbmlSocialLink[];
    },
    Omit<HTMLAttributes<HTMLDivElement>, "content">
>({
    type: HbmlElements.SOCIAL_LINKS,
    Component: forwardRef(function HbmlStackNodeComponentInternal(
        { node, children, ...props },
        ref: ForwardedRef<HTMLDivElement>
    ) {
        return (
            <SocialLinks {...props} ref={ref}>
                {node.links?.map((link, index) => {
                    return <SocialLink type={link.type} href={link.href} key={index} />;
                })}
                {children}
            </SocialLinks>
        );
    }),
});
