import React, { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { Stack, StackColumns } from "../Stack";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlStackNode = hbmlNodeFactory<
    typeof HbmlElements.STACK,
    {
        columns?: StackColumns;
        rowGap?: number;
        columnGap?: number;
    },
    Omit<HTMLAttributes<HTMLDivElement>, "content">
>({
    type: HbmlElements.STACK,
    Component: forwardRef(function HbmlStackNodeComponentInternal(
        { node, style, ...props },
        ref: ForwardedRef<HTMLDivElement>
    ) {
        const { rowGap, columnGap } = node;
        const finalStyle = { ...style, rowGap: rowGap ?? columnGap, columnGap: columnGap };
        return <Stack columns={node.columns ?? 2} style={finalStyle} {...props} ref={ref} />;
    }),
});
