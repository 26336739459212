import { MarkRequired } from "ts-essentials";

import { CurationProductFragment } from "../apiHooks/graphql";
import { CurationProductClickEvent } from "../custom-events";
import { ProductsContainerGrid } from "../product";
import { HbmlCurationProductsV2Props } from "./HbmlComponents";

export type CurationProductsGridProps = MarkRequired<HbmlCurationProductsV2Props, "ProductCardComponent"> & {
    products: CurationProductFragment[];
};

export function CurationProductsGrid({
    products,
    ProductCardComponent,
    productHrefFactory,
    ...otherProps
}: CurationProductsGridProps) {
    return (
        <ProductsContainerGrid {...otherProps}>
            {products.map((entry, position) => (
                <ProductCardComponent
                    {...entry}
                    key={entry.id}
                    onClick={(event, item) => {
                        event.target.dispatchEvent(new CurationProductClickEvent({ item, position }));
                    }}
                    orientation="vertical"
                    tileContentMode="column"
                    href={productHrefFactory({ slug: entry.slug, id: entry.id })}
                />
            ))}
        </ProductsContainerGrid>
    );
}
