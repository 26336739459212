import { AssetUrl } from "@holibob-packages/vault";

import {
    MarketPlaceProductHorizontal,
    MarketPlaceProductTileProps,
    MarketPlaceProductTileVertical,
} from "../components";
import { CurationProductFragment } from "../gql-request";
import { useFormattedPrice } from "../hooks/useFormattedPrice";
import { ProductItem } from "../types";
import { ProductCardContentMode, ProductCardOrientation } from "./types";

type MarketPlaceTileConditionalProps = Pick<
    MarketPlaceProductTileProps,
    | "href"
    | "newTab"
    | "height"
    | "imageMaxWidth"
    | "showFavouriteButton"
    | "onFavouriteChange"
    | "guidePricePricingData"
    | "isSmallTile"
>;

export type ProductCardV2Props = CurationProductFragment &
    MarketPlaceTileConditionalProps & {
        orientation: ProductCardOrientation;
        tileContentMode?: ProductCardContentMode;
        currency?: string;
        onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: ProductItem) => void;
    };

export function ProductCardV2({
    name,
    metaList,
    previewImage,
    cancellationPolicy,
    reviewCount,
    reviewRating,
    holibobGuidePrice,
    href,
    height,
    imageMaxWidth,
    guidePricePricingData,
    newTab,
    showFavouriteButton,
    isFavourite,
    onFavouriteChange,
    isSmallTile,
    id,
    banner,
    onClick,
    tileContentMode,
    orientation,
    currency,
}: ProductCardV2Props) {
    // product fields

    const formattedPrice = useFormattedPrice(holibobGuidePrice?.gross ?? 0, {
        currency: currency ?? holibobGuidePrice?.currency,
    });

    const minDuration = metaList.nodes.find(({ type }) => type === "MIN_DURATION")?.value;
    const maxDuration = metaList.nodes.find(({ type }) => type === "MAX_DURATION")?.value;
    const previewImageId = previewImage?.id;

    const tileProps: MarketPlaceProductTileProps = {
        href,
        title: name ?? "",
        height,
        ...(previewImageId && { imageAssetUrl: new AssetUrl({ id: previewImageId, type: "productImage" }) }),
        imageMaxWidth,
        hasFreeCancellation: cancellationPolicy.hasFreeCancellation ?? false,
        guidePriceFormattedText: formattedPrice,
        ...(minDuration && { minDuration }),
        ...(maxDuration && { maxDuration }),
        ...(reviewCount && { reviewCount }),
        ...(reviewRating && { reviewRating }),
        guidePricePricingData: guidePricePricingData ?? holibobGuidePrice?.pricingData,
        newTab,
        showFavouriteButton,
        isFavourite: !!isFavourite,
        onFavouriteChange,
        banner,
        isSmallTile: isSmallTile ?? false,
        onClick(event) {
            onClick?.(event, { id, name: name ?? "" });
        },
    };

    return orientation === "vertical" ? (
        <MarketPlaceProductTileVertical {...tileProps} tileContentMode={tileContentMode} />
    ) : (
        <MarketPlaceProductHorizontal {...tileProps} />
    );
}
