import { styled } from "@mui/material/styles";
import { CSSProperties, ForwardedRef, forwardRef, HTMLAttributeAnchorTarget, HTMLAttributes } from "react";

import type { HbmlElementNode } from "@holibob-packages/hbml";

import { HbmlRendererNodes } from "../HbmlRenderer";
import { MediaObject } from "../MediaObject";
import { MediaObjectPlacement } from "../MediaObjectPlacement";

export type HbmlMediaObjectNodeAttributesType = {
    style?: CSSProperties;
    placement?: MediaObjectPlacement;
    href?: string;
    target?: HTMLAttributeAnchorTarget;
};

export type HbmlMediaObjectNodeType = HbmlElementNode & HbmlMediaObjectNodeAttributesType;

const Wrapper = styled(MediaObject)(({ theme }) => ({
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    " .MuiTypography-root": {
        color: "inherit !important",
    },
}));

const LinkWrapper = styled("a")(({ theme }) => ({
    position: "relative",
    "&:after": {
        content: '""',
        position: "absolute",
        zIndex: 10,
        top: 0,
        left: 0,
        display: "block",
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        borderRadius: theme.shape.borderRadius,
        transition: theme.transitions.create("background-color", {
            duration: theme.transitions.duration.short,
        }),
    },
    "&:hover": {
        "&:after": {
            backgroundColor: "rgba(255,255,255, 0.1)",
        },
    },
}));
export const HbmlMediaObjectNodeComponent = forwardRef(function HbmlMediaObjectNodeComponentInternal(
    {
        node,
        ...props
    }: {
        node: HbmlMediaObjectNodeType;
    } & HTMLAttributes<HTMLDivElement>,
    ref: ForwardedRef<HTMLDivElement>
) {
    const content = <Wrapper style={node.style} placement={node.placement} {...props} ref={ref} />;

    if (node.href) {
        return (
            <LinkWrapper href={node.href} target={node.target}>
                {content}
            </LinkWrapper>
        );
    }
    return content;
});

export function HbmlMediaObjectNode({ content }: { content: HbmlMediaObjectNodeType }) {
    return (
        <HbmlMediaObjectNodeComponent node={content}>
            <HbmlRendererNodes content={content.children} />
        </HbmlMediaObjectNodeComponent>
    );
}
