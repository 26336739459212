import React from "react";

import { AssetUrl } from "@holibob-packages/vault";

import { CurationCardWithProductCount } from "../../curation/CurationCardWithProductCount";
import { CurationsContainer } from "../../curation/CurationsContainer";
import { CurationContentItemCurationWithProductCountFragment } from "../../gql-request";
import { useNextTranslation } from "../../hooks/useNextTranslation";
import { HbmlCurationsGridNode, HbmlCurationsSliderNode } from "../HbmlComponents";

type CurationListProps = {
    curations: CurationContentItemCurationWithProductCountFragment[];
    curationIdsPath?: string[];
    mode: HbmlCurationsGridNode["mode"] | HbmlCurationsSliderNode["mode"];
};

export function CurationList(props: CurationListProps) {
    const { curations, curationIdsPath, mode } = props;
    const cardMaxWidth = mode === "grid" ? 500 : 250;
    const [t] = useNextTranslation("hbml");

    return (
        <CurationsContainer {...props}>
            {curations.map((entry) => {
                const asset = entry.heroImageAsset ? AssetUrl.fromString(entry.heroImageAsset.uri).unwrap() : undefined;
                return (
                    <CurationCardWithProductCount
                        key={entry.id}
                        curationId={entry.id}
                        slug={entry.slug}
                        curationIdsPath={curationIdsPath}
                        title={entry.name}
                        imageAssetUrl={asset!}
                        subTitle={
                            entry.productCount
                                ? t("curationsV2.activities.label", { count: entry.productCount })
                                : undefined
                        }
                        maxWidth={cardMaxWidth}
                    />
                );
            })}
        </CurationsContainer>
    );
}
