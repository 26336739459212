import { Button, Skeleton } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import React, { HTMLAttributes } from "react";

import { AssetUrl } from "@holibob-packages/vault";

import { Copy } from "../components/Copy";
import { FavouriteButton } from "../components/FavouriteButton";
import { HoverableCard } from "../components/HoverableCard";
import { ImageTile, ImageTileOverlayHeader } from "../components/ImageTile";
import { MinPrice } from "../components/MinPrice";
import { Ribbon } from "../components/Ribbon";
import { Banner, useProductPromotionConfig } from "../hooks";
import { useProductTranslation } from "../hooks/useProductTranslation";
import { Link, LinkProps } from "../navigation/Link";
import { ProductItem } from "../types";
import { ProductDurationMetaData, ProductFreeCancellation, ProductInstantConfirmation } from "./ProductMetaData";
import { ProductReviewsInfo } from "./ProductReviewsInfo";

export { ProductsContainerSkeleton } from "./ProductsContainerSkeleton";

export type FavouriteProps = {
    isFavourite?: boolean;
    onFavouriteChange?: (isFavourite: boolean) => void;
};

export type ProductCardHeaderSize = "normal" | "small";
export type ProductCardProps = {
    href: string;
    product: ProductCardProductInfo;
    newTab?: boolean;
    headerSize?: ProductCardHeaderSize;
    ctaSize?: ButtonProps["size"];
    showDescription?: boolean;
    showReviews?: boolean;
    showMeta?: boolean;
    showFavouriteButton?: boolean;
    showCopyProductId?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: ProductItem) => void;
} & Omit<FavouriteProps, "children"> &
    Omit<LinkProps, "children" | "as" | "onClick">;
export type ProductCardProductInfo = {
    id: string;
    name: string;
    promotionType?: string;
    description?: string;
    isFeatured?: boolean;
    isFavourite?: boolean;
    cancellationPolicy?: {
        hasFreeCancellation: boolean;
    };
    metaList?: {
        nodes: Array<{
            type: string;
            value: string;
        }>;
    };
    holibobGuidePrice?: {
        gross: number;
        currency: string;
        pricingData?: Record<string, unknown>;
    };
    guidePriceType?: string;
    reviewRating?: number;
    reviewCount?: number;
    banner?: Banner;
    previewImage?: ProductCardProductPreviewImage;
};

export type ProductCardProductPreviewImage = {
    id: string;
};

const MAX_WIDTH = 400;
const ZOOM_SCALE = 1.2;
const CardWrapper = styled(HoverableCard)(({ theme }) => ({
    padding: 0,
    maxWidth: MAX_WIDTH,
    overflow: "unset",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    minWidth: 230,
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
    "&:after": {
        height: "calc(100% + 2px)",
        width: "calc(100% + 2px)",
        top: -1,
        left: -1,
        borderRadius: theme.shape.borderRadius,
    },
    "&:hover": {
        '[data-ref="product-card-picture"]': {
            img: {
                transform: `scale(${ZOOM_SCALE})`,
            },
            "&:after": {
                opacity: 0.2,
            },
        },
    },
}));

const CardLayout = styled("div")(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
}));

const ProductCardTitleWrapper = styled("div")(({ theme }) => ({
    gridArea: "title",
    display: "flex",
    gap: theme.spacing(1),
}));

const ProductCardTitleText = styled("h3")(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    margin: "unset",
    flexGrow: 100,
    fontSize: 18,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightRegular,
}));

const ProductCardTitleCopy = styled(Copy)({
    alignSelf: "center",
});

function ProductCardTitle({ title, id, showCopyProductId }: { title: string; id: string; showCopyProductId: boolean }) {
    return (
        <ProductCardTitleWrapper>
            <ProductCardTitleText>{title}</ProductCardTitleText>
            {showCopyProductId && <ProductCardTitleCopy size="small" value={id} label="Product Id" />}
        </ProductCardTitleWrapper>
    );
}

const LinkWrapper = styled(Link)({
    textDecoration: "none",
    display: "block",
    height: "100%",
    boxSizing: "border-box",
});

const ProductCardButton = styled(Button)(({ theme }) => ({
    gridArea: "cta",
    textTransform: "none",
    alignSelf: "end",
    color: theme.palette.common.white,
    width: 100,
    padding: theme.spacing(1),
}));

const ProductCardDescription = styled("div")(({ theme }) => ({
    gridArea: "description",
    display: "-webkit-box",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    fontSize: 14,
    lineHeight: 1.4,
    fontFamily: theme.typography.fontFamily,
}));

const ProductMinPriceWrapper = styled(MinPrice)({
    gridArea: "minPrice",
});

export function ProductMinPrice({ product }: { product: ProductCardProductInfo }) {
    const [t] = useProductTranslation();
    const { guidePriceType, holibobGuidePrice } = product;
    if (!holibobGuidePrice) return null;

    let priceType: string | undefined;
    if (guidePriceType === "PER_GROUP") {
        priceType = t("label.perGroup");
    }
    return (
        <ProductMinPriceWrapper
            size="large"
            amount={holibobGuidePrice.gross}
            currency={holibobGuidePrice.currency}
            pricingData={holibobGuidePrice.pricingData}
            extraLabel={priceType}
        />
    );
}

const ProductCardContent = styled("div")(({ theme }) => ({
    padding: theme.spacing(1.5),
    gap: theme.spacing(2),
    display: "grid",
    gridTemplateAreas: '"title title" "content content" "cta minPrice"',
    gridTemplateRows: "min-content auto min-content",
    gridTemplateColumns: "auto minmax(90px, min-content)",
    flexGrow: 100,
}));

const ProductCardContentInner = styled("div")(({ theme }) => ({
    gridArea: "content",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
}));

export function ProductCard({
    href,
    newTab,
    product,
    onFavouriteChange,
    isFavourite,
    headerSize,
    showDescription = true,
    showReviews = true,
    showMeta = true,
    showFavouriteButton = true,
    showCopyProductId = true,
    ctaSize,
    onClick,
    ...props
}: ProductCardProps) {
    const [t] = useProductTranslation();
    const learnMoreLabel = t("label.learnMore");

    const { reviewRating, reviewCount } = product;
    return (
        <LinkWrapper
            data-testid="productCard"
            href={href}
            newTab={newTab}
            onClick={(event) => onClick?.(event, { id: product.id, name: product.name })}
            {...props}
        >
            <CardWrapper>
                <CardLayout>
                    <ProductCardHeader
                        size={headerSize}
                        showFavouriteButton={showFavouriteButton}
                        onFavouriteChange={onFavouriteChange}
                        isFavourite={isFavourite}
                        product={product}
                    />
                    <ProductCardContent>
                        <ProductCardTitle title={product.name} id={product.id} showCopyProductId={showCopyProductId} />
                        <ProductCardContentInner>
                            {showDescription && product.description && (
                                <ProductCardDescription data-testid="productCardDescription">
                                    {product.description}
                                </ProductCardDescription>
                            )}
                            {showReviews && reviewRating && reviewCount ? (
                                <ProductReviewsInfo rating={reviewRating} reviewsNumber={reviewCount} />
                            ) : undefined}
                            {showMeta && <ProductCardMeta product={product} />}
                        </ProductCardContentInner>
                        <ProductMinPrice product={product} />
                        <ProductCardButton
                            data-leave-me-alone
                            color="primary"
                            size={ctaSize}
                            disableElevation={true}
                            variant="contained"
                            data-testid="learn-more-button"
                        >
                            {learnMoreLabel}
                        </ProductCardButton>
                    </ProductCardContent>
                </CardLayout>
            </CardWrapper>
        </LinkWrapper>
    );
}

const HEADER_HEIGHT: Record<ProductCardHeaderSize, number> = {
    normal: 200,
    small: 130,
};

function ProductCardHeader({
    product,
    showFavouriteButton,
    onFavouriteChange,
    size = "normal",
    isFavourite,
}: {
    product: ProductCardProductInfo;
    showFavouriteButton: boolean;
    size?: ProductCardHeaderSize;
} & FavouriteProps) {
    const { previewImage } = product;
    const { promotionType, isFeatured, banner } = product;
    const bannerConfig = useProductPromotionConfig({ banner, isFeatured, promotionType });

    const imageAssetUrl = previewImage
        ? AssetUrl.enforceUrlForIdOrUrl(previewImage.id, {
              type: "productImage",
          }).unwrap()
        : undefined;

    const height = HEADER_HEIGHT[size];

    return (
        <ImageTile
            imageAssetUrl={imageAssetUrl}
            alt={product.name}
            height={height}
            maxWidth={MAX_WIDTH}
            hoverOpacity={0.8}
            hoverZoomScale={1.2}
        >
            {showFavouriteButton && (
                <ImageTileOverlayHeader backgroundGradient={false}>
                    <FavouriteButton isFavourite={isFavourite ?? false} onChange={onFavouriteChange} />
                </ImageTileOverlayHeader>
            )}
            {bannerConfig && <Ribbon color={bannerConfig.color}>{bannerConfig.label}</Ribbon>}
        </ImageTile>
    );
}

const ProductCardMetaWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
}));

function ProductCardMeta({ product }: { product: ProductCardProductInfo }) {
    return (
        <ProductCardMetaWrapper>
            <ProductCardDuration product={product} />
            <ProductInstantConfirmation size="small" />
            <ProductCardFreeCancellation product={product} />
        </ProductCardMetaWrapper>
    );
}

function ProductCardDuration({ product: { metaList } }: { product: ProductCardProductInfo }) {
    if (!metaList) return null;

    const minDuration = metaList.nodes.find(({ type }) => type === "MIN_DURATION")?.value;
    const maxDuration = metaList.nodes.find(({ type }) => type === "MAX_DURATION")?.value;

    return (
        <ProductDurationMetaData
            showLabel={false}
            size="small"
            minDuration={minDuration ?? null}
            maxDuration={maxDuration ?? null}
        />
    );
}

function ProductCardFreeCancellation({ product: { cancellationPolicy } }: { product: ProductCardProductInfo }) {
    if (!cancellationPolicy) {
        return null;
    }

    const { hasFreeCancellation } = cancellationPolicy;

    if (!hasFreeCancellation) return null;

    return <ProductFreeCancellation size="small" />;
}

export type ProductCardSkeletonProps = {
    headerSize?: ProductCardHeaderSize;
} & HTMLAttributes<HTMLDivElement>;

const FixedSkeleton = styled(Skeleton)({
    transform: "unset",
});

const ContentSkeletonWrapper = styled("div")(({ theme }) => ({
    gap: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    gridArea: "content",
}));

const MinPriceSkeleton = styled(FixedSkeleton)({
    alignSelf: "center",
    gridArea: "minPrice",
});

export function ProductCardSkeleton({ headerSize = "normal", ...props }: ProductCardSkeletonProps) {
    return (
        <CardWrapper {...props}>
            <Skeleton variant="rectangular" height={HEADER_HEIGHT[headerSize]} />
            <ProductCardContent>
                <FixedSkeleton style={{ gridArea: "title" }} height={30} />
                <ContentSkeletonWrapper>
                    <FixedSkeleton height={16} width="80%" />
                    <FixedSkeleton height={16} width="50%" />
                    <FixedSkeleton height={16} width="70%" />
                </ContentSkeletonWrapper>
                <FixedSkeleton style={{ gridArea: "cta" }} height={40} />
                <MinPriceSkeleton height={24} />
            </ProductCardContent>
        </CardWrapper>
    );
}
