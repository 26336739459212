import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { SvgIconProps } from "@mui/material";
import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";
import { useFavouriteProduct } from "hooks/useFavouriteProducts";
import noop from "lodash/noop";

import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()((theme) => ({
    favouriteIcon: {
        cursor: "pointer",
    },
    favouriteIconTrue: {
        color: "red",
    },
    favouriteIconFalse: {
        color: theme.palette.primary.main,
    },
}));

type FavouriteButtonProps = {
    isFavourite: boolean;
    onClick?: (event: React.MouseEvent<SVGElement>, value: boolean) => void;
} & Omit<SvgIconProps, "onClick">;
export const FavouriteButton = ({ onClick = noop, isFavourite, className, ...props }: FavouriteButtonProps) => {
    const { classes, cx } = useStyles();
    const canUseFavourite = useEntityConfigurationValueByKey("SETTING.CAN_USE_FAVOURITE");

    if (canUseFavourite) {
        return (
            <>
                {isFavourite ? (
                    <FavoriteIcon
                        {...props}
                        className={cx(classes.favouriteIconTrue, className)}
                        onClick={(event) => onClick(event, false)}
                    />
                ) : (
                    <FavoriteBorderIcon
                        {...props}
                        className={cx(classes.favouriteIconFalse, className)}
                        onClick={(event) => onClick(event, true)}
                    />
                )}
            </>
        );
    } else {
        return null;
    }
};

type FavouriteProductButtonProps = {
    productId: string;
    className?: string;
};
const FavouriteProductButton = ({ productId, className }: FavouriteProductButtonProps) => {
    const [isFavourite, setIsFavourite] = useFavouriteProduct(productId);
    const { classes, cx } = useStyles();
    const canUseFavourite = useEntityConfigurationValueByKey("SETTING.CAN_USE_FAVOURITE");

    if (canUseFavourite) {
        return (
            <FavouriteButton
                data-testid="favouriteButton"
                fontSize="large"
                className={cx(classes.favouriteIcon, className)}
                onClick={(event, value) => {
                    event.preventDefault();
                    void setIsFavourite(value);
                }}
                isFavourite={isFavourite}
            />
        );
    } else {
        return null;
    }
};

export default FavouriteProductButton;
