import { TypographyProps } from "@mui/material";
import React, { CSSProperties, ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { HbmlNode } from "@holibob-packages/hbml";

import { FadeUpAnimationWrapper } from "../../components";
import { useColourResolver } from "../../hooks";
import { HbmlElements } from "./HbmlElements";
import { Background } from "./common/Background";
import { ContentWidth, PageLayout } from "./common/PageLayout";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";
import { useSectionStyles } from "./hooks/useSectionStyles";

type ConfiguredStyles = "color" | "marginTop" | "marginBottom" | "paddingTop" | "paddingBottom";

export const HbmlSectionNode = hbmlNodeFactory<
    typeof HbmlElements.SECTION,
    {
        type: typeof HbmlElements.SECTION;
        children: HbmlNode[];
        background?: Background;
        align?: TypographyProps["align"];
        contentWidth?: ContentWidth;
        style?: Pick<CSSProperties, ConfiguredStyles>;
    },
    Omit<HTMLAttributes<HTMLDivElement>, "content"> & { withSectionFadeUpAnimation?: boolean }
>({
    type: HbmlElements.SECTION,
    Component: forwardRef(function ComponentInternal(
        { node, withSectionFadeUpAnimation, ...props },
        ref: ForwardedRef<HTMLDivElement>
    ) {
        const { background, contentWidth, style, align } = node;
        const colourResolver = useColourResolver();

        const finalStyle = useSectionStyles(style, background, colourResolver, align);
        const container = (
            <PageLayout ref={ref} contentWidth={contentWidth ?? "default"} style={finalStyle} {...props} />
        );

        if (withSectionFadeUpAnimation) {
            return <FadeUpAnimationWrapper>{container}</FadeUpAnimationWrapper>;
        }

        return <>{container}</>;
    }),
});
