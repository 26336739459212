import { HTMLAttributes } from "react";

import { PoweredBy } from "../index";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlPoweredByHolibobNode = hbmlNodeFactory<
    typeof HbmlElements.POWERED_BY_HOLIBOB,
    Record<string, unknown>,
    Omit<HTMLAttributes<HTMLDivElement>, "content">
>({
    type: HbmlElements.POWERED_BY_HOLIBOB,
    Component: function HbmlPoweredByHolibobNodeInternal(props) {
        return <PoweredBy {...props} />;
    },
});
