import { Link } from "../navigation/Link";
import { HoverableCardV2 } from "./HoverableCard";
import { ImageTile, ImageTileOverlayFooter, ImageTileOverlayHeader } from "./ImageTile";
import {
    DEFAULT_MARKET_PLACE_TILE_HEIGHT,
    MarketPlaceTileOverlayTitle,
    MarketPlaceTileProps,
} from "./MarketPlace/MarketPlaceTile/MarketPlaceTile";

export type MarketPlaceTileAProps = MarketPlaceTileProps & {
    headerComponent?: React.ReactNode;
};

export const MarketPlaceTileA = ({
    title,
    subTitle,
    imageAssetUrl,
    headerComponent,
    height = DEFAULT_MARKET_PLACE_TILE_HEIGHT,
    maxWidth = 250,
    ...props
}: MarketPlaceTileAProps) => {
    return (
        <Link {...props} passHref legacyBehavior>
            <a>
                <HoverableCardV2 style={{ maxWidth }}>
                    <ImageTile
                        imageAssetUrl={imageAssetUrl}
                        alt={title ?? "item-image"}
                        height={height}
                        maxWidth={maxWidth}
                        hoverOpacity={0.8}
                        hoverZoomScale={1.1}
                    >
                        {headerComponent && <ImageTileOverlayHeader>{headerComponent}</ImageTileOverlayHeader>}
                        {title && (
                            <ImageTileOverlayFooter>
                                <MarketPlaceTileOverlayTitle title={title} subTitle={subTitle} />
                            </ImageTileOverlayFooter>
                        )}
                    </ImageTile>
                </HoverableCardV2>
            </a>
        </Link>
    );
};
