import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";

export const HeroSearchCard = styled(Card)(({ theme }) => ({
    background: "rgba(255, 255, 255, 0.85)",
    overflow: "visible",
    border: "none",
    boxShadow: "none",
    alignSelf: "center",
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        width: "60%",
    },
    [theme.breakpoints.up("lg")]: {
        width: (8 / 12) * 100 + "%",
    },
}));
