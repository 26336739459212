import { styled } from "@mui/material/styles";
import { forwardRef, ForwardedRef, HTMLAttributes } from "react";

import { ScrollableContainer, ScrollableContainerProps } from "../components/ScrollableContainer";
import { ScrollableContainerItem } from "../components/ScrollableContainerItem";
import { CurationCardSkeleton } from "./CurationCard";

const CurationItemWrapper = styled(ScrollableContainerItem)({
    width: 250,
});

type CurationsContainerSliderSkeletonProps = { cardHeight?: number } & HTMLAttributes<HTMLDivElement>;

export const CurationsContainerSliderSkeleton = forwardRef(function CurationsContainerSliderSkeleton(
    { cardHeight, ...props }: CurationsContainerSliderSkeletonProps & Omit<ScrollableContainerProps, "children">,
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <ScrollableContainer ref={ref} {...props}>
            <CurationItemWrapper>
                <CurationCardSkeleton height={cardHeight} />
            </CurationItemWrapper>
            <CurationItemWrapper>
                <CurationCardSkeleton height={cardHeight} />
            </CurationItemWrapper>
            <CurationItemWrapper>
                <CurationCardSkeleton height={cardHeight} />
            </CurationItemWrapper>
            <CurationItemWrapper>
                <CurationCardSkeleton height={cardHeight} />
            </CurationItemWrapper>
        </ScrollableContainer>
    );
});
