import { styled } from "@mui/material/styles";

import { Link } from "../navigation/Link";

export const LinkWrapper = styled(Link)({
    textDecoration: "none",
    display: "inline-block",
    height: "100%",
    boxSizing: "border-box",
    cursor: "pointer",
});
