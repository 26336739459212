import { ProductCardContentMode } from "../../../product";
import {
    TileWrapper,
    TileContentWrapperSkeleton,
    TileContentDetailsWrapper,
    ImageSkeleton,
    FixedSkeleton,
    DEFAULT_IMAGE_HEIGHT,
} from "./MarketPlaceProductTileLayoutComponents";

export type MarketPlaceProductTileVerticalSkeletonProps = {
    imageMaxWidth?: number;
    tileContentMode?: ProductCardContentMode;
};
export const MarketPlaceProductTileVerticalSkeleton = ({
    imageMaxWidth,
    tileContentMode = "row",
}: MarketPlaceProductTileVerticalSkeletonProps) => {
    return (
        <TileWrapper href="" newTab={false} sx={{ maxWidth: imageMaxWidth }}>
            <ImageSkeleton variant="rounded" height={DEFAULT_IMAGE_HEIGHT} width={imageMaxWidth} />
            <TileContentWrapperSkeleton data-tile-content={tileContentMode}>
                <TileContentDetailsWrapper>
                    <FixedSkeleton height={48} width={tileContentMode === "column" ? "90%" : "100%"} />
                    <FixedSkeleton height={20} width="40%" />
                    <FixedSkeleton height={20} width="85%" />
                </TileContentDetailsWrapper>

                <FixedSkeleton
                    height={tileContentMode === "column" ? 48 : 20}
                    width={tileContentMode === "column" ? "15%" : "30%"}
                />
            </TileContentWrapperSkeleton>
        </TileWrapper>
    );
};
