import { Theme, useTheme } from "@mui/material/styles";

import { ColourResolver, resolveColour } from "../utils/resolveColour";

export function useColourResolver(theme?: Theme): ColourResolver {
    const currentTheme = useTheme();
    return (ref) => {
        return resolveColour(ref, (theme ?? currentTheme).palette);
    };
}
