import { styled } from "@mui/material/styles";
import React from "react";

import { AssetUrl } from "@holibob-packages/vault";

import { Banner, useLanguage } from "../../../hooks";
import { useProductTranslation } from "../../../hooks/useProductTranslation";
import { LabelList, LabelListItem } from "../../../product/V2/LabelList";
import { ProductReviewsInfoV2 } from "../../../product/V2/ProductReviewsInfoV2";
import { ProductCardContentMode } from "../../../product/types";
import "../../../types";
import { formatDurationFromString } from "../../../utils/formatters/formatDuration";
import { FavouriteButton } from "../../FavouriteButton";
import { MarketPlaceMinPrice } from "../../MarketPlaceMinPrice";
import { PriceType, PricingData } from "../../Price";
import { Ribbon } from "../../Ribbon";
import { Tag, TagProps } from "../../TagList";
import { Typography } from "../../Typography";
import {
    ImageTileContainer,
    ImageTileHeaderContainer,
    MarketMinPriceStyledVertical,
    TileContentDetailsWrapper,
    TileContentWrapper,
    TileWrapper,
    TileWrapperHorizontal,
    TitleText,
    DEFAULT_IMAGE_HEIGHT,
    MAX_TILE_IMAGE_WIDTH,
} from "./MarketPlaceProductTileLayoutComponents";

export type MarketPlaceProductTileProps = {
    title: string;
    href: string;
    showFavouriteButton?: boolean;
    isFavourite?: boolean;
    newTab?: boolean;
    guidePriceFormattedText?: string;
    guidePriceType?: PriceType;
    guidePricePricingData?: PricingData;
    minDuration?: string;
    maxDuration?: string;
    reviewCount?: number;
    reviewRating?: number;
    tagLabel?: string;
    hasFreeCancellation?: boolean;
    height?: number;
    banner?: Banner | null;

    // image related props
    imageAssetUrl?: AssetUrl;
    imageMaxWidth?: number;
    imageMinWidth?: number;

    isSmallTile?: boolean;

    onTagClick?: TagProps["onClick"];
    onFavouriteChange?: (isFavourite: boolean) => void;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export type MarketPlaceProductTileVerticalProps = MarketPlaceProductTileProps & {
    tileContentMode?: ProductCardContentMode;
};
export const MarketPlaceProductTileVertical = ({
    tileContentMode = "row",
    ...props
}: MarketPlaceProductTileVerticalProps) => {
    const { imageMaxWidth = MAX_TILE_IMAGE_WIDTH } = props;

    return (
        <TileWrapper
            href={props.href}
            newTab={props.newTab}
            sx={{ maxWidth: imageMaxWidth }}
            data-testid="productCard"
            data-is-small-tile={props.isSmallTile}
            onClick={props.onClick}
        >
            <MarketPlaceProductTileImage {...props} withDimOverlay={true} />

            {tileContentMode === "row" ? <TileContentHorizontal {...props} /> : <TileContentVertical {...props} />}
        </TileWrapper>
    );
};

const TileContentHorizontal = (props: MarketPlaceProductTileProps) => {
    const { guidePricePricingData } = props;
    return (
        <TileContentDetailsWrapper>
            <TitleHorizontal title={props.title} />

            <ReviewDuration
                reviewCount={props.reviewCount}
                reviewRating={props.reviewRating}
                minDuration={props.minDuration}
                maxDuration={props.maxDuration}
            />

            <ProductInfoTags hasFreeCancellation={props.hasFreeCancellation} />

            {props.guidePriceFormattedText && (
                <MarketPlaceMinPrice
                    data-testid="product-price"
                    priceType={props.guidePriceType}
                    priceFormattedText={props.guidePriceFormattedText}
                    guidePricePricingData={guidePricePricingData}
                />
            )}
        </TileContentDetailsWrapper>
    );
};

const TileContentVertical = (props: MarketPlaceProductTileProps) => {
    const { guidePricePricingData } = props;
    return (
        <TileContentWrapper>
            <TileContentDetailsWrapper>
                <Title title={props.title} />

                <ReviewDuration
                    reviewCount={props.reviewCount}
                    reviewRating={props.reviewRating}
                    minDuration={props.minDuration}
                    maxDuration={props.maxDuration}
                />

                <ProductInfoTags hasFreeCancellation={props.hasFreeCancellation} />
            </TileContentDetailsWrapper>

            {props.guidePriceFormattedText && (
                <MarketMinPriceStyledVertical
                    priceType={props.guidePriceType}
                    priceFormattedText={props.guidePriceFormattedText}
                    guidePricePricingData={guidePricePricingData}
                    layout="vertical"
                />
            )}
        </TileContentWrapper>
    );
};

export const MarketPlaceProductHorizontal = ({ onClick, ...props }: MarketPlaceProductTileProps) => {
    return (
        <TileWrapperHorizontal
            href={props.href}
            newTab={props.newTab}
            data-is-small-tile={props.isSmallTile}
            onClick={onClick}
        >
            <MarketPlaceProductTileImage {...props} withDimOverlay={false} />
            <TileContentHorizontal {...props} />
        </TileWrapperHorizontal>
    );
};

const SIZES = [300, 500, 700, 900, 1100, 1300];

type MarketPlaceProductTileImageProps = Pick<
    MarketPlaceProductTileProps,
    | "imageAssetUrl"
    | "showFavouriteButton"
    | "isFavourite"
    | "title"
    | "tagLabel"
    | "onTagClick"
    | "height"
    | "onFavouriteChange"
    | "banner"
    | "isSmallTile"
> & {
    withDimOverlay?: boolean;
};
const MarketPlaceProductTileImage = (props: MarketPlaceProductTileImageProps) => {
    const {
        title,
        imageAssetUrl,
        height = DEFAULT_IMAGE_HEIGHT,
        showFavouriteButton,
        isFavourite,
        onFavouriteChange,
        tagLabel,
        onTagClick,
        banner,
        isSmallTile,
    } = props;
    const imageTileProp = isSmallTile
        ? { maxWidth: 130 }
        : { srcSetSizes: SIZES, sizes: "(min-width: 600px) 330px, calc(100vw - 50px)" };
    return (
        <ImageTileContainer
            data-testid="product-image"
            data-with-overlay={props.withDimOverlay}
            imageAssetUrl={imageAssetUrl}
            alt={title}
            height={height}
            hoverOpacity={0.8}
            hoverZoomScale={1.1}
            data-image-tile-container="true"
            {...imageTileProp}
        >
            <ImageTileHeaderContainer>
                {/*`
                    Favourite button needs to be wrapped in div,
                    in case when it's hidden, tags should remain aligned to the right
                */}
                <div>
                    {showFavouriteButton && (
                        <FavouriteButton
                            size="small"
                            isFavourite={isFavourite ?? false}
                            onChange={onFavouriteChange}
                            color="light"
                        />
                    )}
                </div>
                {banner && <Ribbon color={banner.backgroundColor}>{banner.text}</Ribbon>}
                {tagLabel && (
                    <Tag onClick={onTagClick ? onTagClick : undefined} disabled={!onTagClick}>
                        {tagLabel}
                    </Tag>
                )}
            </ImageTileHeaderContainer>
        </ImageTileContainer>
    );
};

type TitleProps = { title: string; className?: string };
const Title = ({ title, className }: TitleProps) => {
    return (
        <TitleText color="primary" variant="title" className={className} data-testid="product-title">
            {title}
        </TitleText>
    );
};

type ReviewDurationProps = {
    reviewCount?: number;
    reviewRating?: number;
    minDuration?: string;
    maxDuration?: string;
};
const ReviewDuration = (props: ReviewDurationProps) => {
    const language = useLanguage();
    const { reviewCount, reviewRating, minDuration, maxDuration } = props;
    const showReviews = typeof reviewCount === "number" && typeof reviewRating === "number";
    const duration = formatDurationFromString(language, minDuration, maxDuration);

    if (!showReviews && !duration) {
        return null;
    }

    return (
        <LabelList data-testid="product-review">
            {showReviews && (
                <LabelListItem>
                    <ProductReviewsInfoV2 reviewCount={reviewCount} reviewRating={reviewRating} />
                </LabelListItem>
            )}

            {duration && (
                <LabelListItem>
                    <Typography component="span" variant="body" size="small">
                        {duration}
                    </Typography>
                </LabelListItem>
            )}
        </LabelList>
    );
};

type ProductInfoTagsProps = {
    hasFreeCancellation?: boolean;
};
const ProductInfoTags = (props: ProductInfoTagsProps) => {
    const [t] = useProductTranslation();
    const instantConfirmationLabel = t("label.instantConfirmation").toLowerCase();

    return (
        <LabelList data-testid="product-info-tags">
            <LabelListItem>
                <Typography color="subtle" variant="body" size="small" component="span">
                    {instantConfirmationLabel}
                </Typography>
            </LabelListItem>

            {props.hasFreeCancellation && (
                <LabelListItem>
                    <Typography color="subtle" variant="body" size="small" component="span">
                        {t("label.freeCancellation").toLowerCase()}
                    </Typography>
                </LabelListItem>
            )}
        </LabelList>
    );
};

export const TitleHorizontal = styled(Title)({
    WebkitLineClamp: 2,
});
