import { styled } from "@mui/material/styles";
import { ReactNode } from "react";

import { useNextTranslation } from "../hooks";
import { Link } from "../navigation";
import { Flex } from "./Flex";
import { Typography } from "./Typography";

type PoweredByProps = {
    children?: ReactNode;
};
export const PoweredBy = ({ children }: PoweredByProps) => {
    const [t] = useNextTranslation("general");
    return (
        <Flex column gap={6}>
            <Typography size="small">Powered By</Typography>
            <Link href={"https://holibob.tech"} newTab={true}>
                <TypographyStyled variant="h2" component="h2" href="https://holibob.tech">
                    holibob
                </TypographyStyled>
            </Link>
            <Link href={"/holibob-privacy-policy"} newTab={true}>
                <Typography size="small">{t("privacyPolicy")}</Typography>
            </Link>
            {children}
        </Flex>
    );
};

const TypographyStyled = styled(Typography)({
    fontFamily: "Gill Sans, Montserrat",
});
