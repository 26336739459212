import { ProductsContainerGrid } from "../product";
import { MarketPlaceProductTileVerticalSkeleton } from "./MarketPlace/MarketPlaceTile/MarketPlaceProductTileVerticalSkeleton";

const DEFAULT_PRODUCTS_COUNT = 6;
type CurationProductsGridSkeletonProps = { maxProducts?: number };
export function CurationProductsGridSkeleton({ maxProducts }: CurationProductsGridSkeletonProps) {
    const itemsCount = getItemsCount(maxProducts);
    const skeletons = Array.from({ length: itemsCount });

    return (
        <ProductsContainerGrid>
            {skeletons.map((_, index) => (
                <MarketPlaceProductTileVerticalSkeleton key={index} tileContentMode="column" />
            ))}
        </ProductsContainerGrid>
    );
}

function getItemsCount(maxProducts?: number) {
    if (!maxProducts) return DEFAULT_PRODUCTS_COUNT;

    return maxProducts > DEFAULT_PRODUCTS_COUNT ? DEFAULT_PRODUCTS_COUNT : maxProducts;
}
