import { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import type { HbmlElementNode } from "@holibob-packages/hbml";

import { HbmlRendererNodes } from "../HbmlRenderer";
import { SectionHeaderContent } from "./common/SectionHeaderContent";

export const HbmlSectionHeaderNodeComponent = forwardRef(function HbmlSectionHeadingNodeComponentInternal(
    { node: _node, ...props }: { node: HbmlElementNode } & HTMLAttributes<HTMLDivElement>,
    ref: ForwardedRef<HTMLDivElement>
) {
    return <SectionHeaderContent ref={ref} {...props} />;
});

export function HbmlSectionHeaderNode({ content }: { content: HbmlElementNode }) {
    return (
        <HbmlSectionHeaderNodeComponent node={content}>
            <HbmlRendererNodes content={content.children} />
        </HbmlSectionHeaderNodeComponent>
    );
}
