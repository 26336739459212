import { styled } from "@mui/material/styles";
import Script from "next/script";
import React, { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

const Wrapper = styled("div")(({ theme }) => ({
    margin: theme.spacing(1),
}));

export const HbmlElfsightNode = hbmlNodeFactory<
    typeof HbmlElements.ELFSIGHT_EMBED,
    {
        key?: string;
        name?: string;
    },
    Omit<HTMLAttributes<HTMLDivElement>, "content">
>({
    type: HbmlElements.ELFSIGHT_EMBED,
    Component: forwardRef(function ComponentInternal({ node, ...props }, ref: ForwardedRef<HTMLDivElement>) {
        return (
            <>
                <Script id="elfsight" src="https://apps.elfsight.com/p/platform.js" />
                <Wrapper className={node.key} ref={ref} {...props} />
            </>
        );
    }),
});
