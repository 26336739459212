import { styled } from "@mui/material/styles";

export const MediaObjectMedia = styled("div")(({ theme }) => ({
    gridArea: "media",
    display: "inline-flex",
    justifySelf: "center",
    alignItems: "var(--media-vertical-placement)",
    [theme.breakpoints.up("md")]: {
        justifySelf: "var(--media-alignment, unset)",
    },
}));
