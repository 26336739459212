import Place from "@mui/icons-material/FmdGoodOutlined";
import Map from "@mui/icons-material/MapOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, AutocompleteInputChangeReason, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ButtonPrimary } from "components/Button";
import TextInput from "components/TextInput";
import { useSearchAutoCompleteQuery } from "gql";
import {
    SearchAutoCompleteCityFragment,
    SearchAutoCompleteCountryFragment,
    SearchAutoCompleteProductFragment,
    SearchAutoCompleteRegionFragment,
} from "gql/gql-request";
import { useProductUrlGenerator } from "hooks/useProductUrlGenerator";
import React, { HTMLAttributes, KeyboardEvent, useRef, useState } from "react";
import { useDebounce } from "use-debounce";

import { ResponsiveImage, Typography } from "@holibob-packages/ui-core/components";
import { GlobalSearchEvent, SearchProductClickEvent } from "@holibob-packages/ui-core/custom-events";
import { useNextTranslation } from "@holibob-packages/ui-core/hooks";
import { useRouter } from "@holibob-packages/ui-core/navigation";
import { Link } from "@holibob-packages/ui-core/navigation";
import { ProductItem } from "@holibob-packages/ui-core/types";
import { urlProductSearch, urlPlace } from "@holibob-packages/url";

import { SearchClearButton } from "./Search";

type SearchAutoComplete =
    | SearchAutoCompleteCityFragment
    | SearchAutoCompleteRegionFragment
    | SearchAutoCompleteCountryFragment
    | SearchAutoCompleteProductFragment;

export type SearchProps = {
    showSearchButton?: boolean;
    autofocus?: boolean;
    showResults?: boolean;
    onChange?: (val: string) => void;
    onSubmit?: (val: string) => void;
    onClear?: () => void;
    onNavigate?: () => void;
};

export function SearchV2({
    showSearchButton = false,
    autofocus,
    showResults = true,
    onChange,
    onClear,
    onNavigate,
    onSubmit,
}: SearchProps) {
    const [t] = useNextTranslation("search");
    const [value, setValue] = useState<SearchAutoComplete | null>(null);
    const [currentHighlightedValue, setCurrentHighlightedValue] = useState<SearchAutoComplete | null>(null);
    const [textValue, setTextValue] = useState("");
    const [debouncedValue] = useDebounce(textValue, 300);
    const productUrlGenerator = useProductUrlGenerator();
    const inputRef = useRef<HTMLInputElement>(null);
    const { push } = useRouter();
    const { data, loading } = useSearchAutoCompleteQuery({
        variables: {
            phrase: debouncedValue,
        },
        skip: !showResults || !debouncedValue || debouncedValue.length < 2,
        // "no-cache" because apollo shows cached results with wrong highlight information
        fetchPolicy: "no-cache",
        onCompleted: () => {
            if (!textValue) return;
            inputRef.current?.dispatchEvent(new GlobalSearchEvent(textValue));
        },
    });
    const results = data?.searchAutoComplete ? [...data.searchAutoComplete, null] : [];
    const onNavigateToProduct = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        data: { item: ProductItem; position: number }
    ) => {
        event.target.dispatchEvent(new SearchProductClickEvent(data));
        onNavigate?.();
    };

    const clearInput = () => {
        setTextValue("");
        setValue(null);
        onClear?.();
    };

    const handleInputChange = (_: unknown, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === "input") {
            setTextValue(value);
            onChange?.(value);
        }
    };

    const handleSubmit = () => {
        if (textValue) {
            onNavigate?.();
            onSubmit?.(textValue);
            push(urlProductSearch(textValue));
        }
    };

    const handleKeyboardSelect = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter" && textValue) {
            onNavigate?.();
            onSubmit?.(textValue);
            if (currentHighlightedValue === null) {
                const place = getPlaceMatchingWithPhrase(results, textValue);
                if (place) {
                    push(urlPlace(place.id));
                } else {
                    push(urlProductSearch(textValue));
                }
            } else if (currentHighlightedValue.__typename === "SearchAutoCompleteProduct") {
                push(productUrlGenerator({ slug: currentHighlightedValue.slug, id: currentHighlightedValue.id }));
            } else if (
                currentHighlightedValue.__typename === "SearchAutoCompleteCity" ||
                currentHighlightedValue.__typename === "SearchAutoCompleteCountry" ||
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                currentHighlightedValue.__typename === "SearchAutoCompleteRegion"
            ) {
                push(urlPlace(currentHighlightedValue.id));
            }
        }
    };

    const handleSeeAllMouseClick = () => {
        onNavigate?.();
        onSubmit?.(textValue);
        const place = getPlaceMatchingWithPhrase(results, textValue);
        if (place) {
            push(urlPlace(place.id));
        } else {
            push(urlProductSearch(textValue));
        }
    };

    return (
        <SearchContainer>
            <Autocomplete
                filterOptions={(x) => x}
                value={value}
                inputValue={textValue}
                onChange={(_, value) => {
                    setValue(value);
                }}
                onInputChange={handleInputChange}
                loading={loading}
                options={results}
                includeInputInList
                filterSelectedOptions
                clearOnBlur={false}
                forcePopupIcon={false}
                fullWidth
                noOptionsText={t("placeholder.searchFor")}
                onHighlightChange={(_, option) => {
                    setCurrentHighlightedValue(option);
                }}
                renderInput={(params) => {
                    const InputProps = {
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end" aria-label="directions">
                                {textValue && <SearchClearButton onClick={clearInput} />}
                                {showSearchButton && (
                                    <ButtonPrimary onClick={handleSubmit}>{t("label.search")}</ButtonPrimary>
                                )}
                            </InputAdornment>
                        ),
                        startAdornment: (
                            <InputAdornment position="start" aria-label="directions">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        placeholder: t("label.startSearch"),
                    };
                    return (
                        <TextInput
                            {...params}
                            inputRef={inputRef}
                            InputProps={InputProps}
                            autoFocus={autofocus}
                            onKeyDown={handleKeyboardSelect}
                        />
                    );
                }}
                getOptionLabel={(option) => option?.name ?? ""}
                renderOption={(params, option) => {
                    const { key, ...restParams } = params as HTMLAttributes<HTMLLIElement> & { key: string };
                    if (option === null) {
                        return (
                            <MoreResults
                                key="more_options"
                                phrase={textValue}
                                params={restParams}
                                handleClick={handleSeeAllMouseClick}
                            />
                        );
                    } else if (option.__typename === "SearchAutoCompleteProduct") {
                        return (
                            <ProductSearchResult
                                key={key}
                                params={restParams}
                                option={option}
                                onNavigate={(event) => {
                                    const item = { id: option.id, name: option.name };
                                    const position = results.indexOf(
                                        (results as unknown as SearchAutoCompleteProductFragment[]).find(
                                            (x) => (x.id = option.id)
                                        )!
                                    );
                                    onNavigateToProduct(event, { item, position });
                                }}
                            />
                        );
                    } else {
                        return (
                            <PlaceSearchResult key={key} params={restParams} option={option} onNavigate={onNavigate} />
                        );
                    }
                }}
                {...(!showResults && { open: false })}
            />
        </SearchContainer>
    );
}

type BoldSubstringProps = {
    text: string;
    range: SearchAutoCompleteCityFragment["nameFoundPhraseRange"];
};

function BoldSubstring({ text, range }: BoldSubstringProps) {
    const { start, end } = range;
    const boldText = text.substring(start, end);
    return (
        <Typography>
            {text.substring(0, start)}
            <Typography component="strong" fontWeight="bold">
                {boldText}
            </Typography>
            {!!end && text.substring(end)}
        </Typography>
    );
}

function MoreResults({
    phrase,
    params,
    handleClick,
}: {
    phrase: string;
    params: HTMLAttributes<HTMLLIElement>;
    handleClick: () => void;
}) {
    const [t] = useNextTranslation("search");
    return (
        phrase && (
            <ResultContainer {...params} onClick={handleClick}>
                <Typography>{t("title.resultsFor", { phrase })}</Typography>
            </ResultContainer>
        )
    );
}

function ProductSearchResult({
    params,
    option,
    onNavigate,
}: {
    params: HTMLAttributes<HTMLLIElement>;
    option: SearchAutoCompleteProductFragment;
    onNavigate?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}) {
    const productUrlGenerator = useProductUrlGenerator();

    return (
        <LinkStyled href={productUrlGenerator({ slug: option.slug, id: option.id })}>
            <ResultContainer {...params} onClick={onNavigate}>
                <IconContainer>
                    {option.image?.uri ? (
                        <ImageStyled src={option.image.uri} height={40} width={40} alt={option.name} />
                    ) : (
                        <Map />
                    )}
                </IconContainer>
                <BoldSubstring text={option.name} range={option.nameFoundPhraseRange} />
            </ResultContainer>
        </LinkStyled>
    );
}

function PlaceSearchResult({
    params,
    option,
    onNavigate,
}: {
    params: HTMLAttributes<HTMLLIElement>;
    option: SearchAutoCompleteCityFragment | SearchAutoCompleteCountryFragment | SearchAutoCompleteRegionFragment;
    onNavigate?: () => void;
}) {
    return (
        <LinkStyled href={urlPlace(option.id)}>
            <ResultContainer {...params} onClick={onNavigate}>
                <IconContainer>
                    <Place />
                </IconContainer>
                <BoldSubstring text={option.name} range={option.nameFoundPhraseRange} />
            </ResultContainer>
        </LinkStyled>
    );
}

function getPlaceMatchingWithPhrase(results: (SearchAutoComplete | null)[], phrase: string) {
    return results.find(
        (result) =>
            (result?.__typename === "SearchAutoCompleteCity" || result?.__typename === "SearchAutoCompleteCountry") &&
            result.name.toLowerCase() === phrase.toLowerCase()
    );
}

const ResultContainer = styled("li")({
    display: "flex",
    alignItems: "center",
});

const IconContainer = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(2),
    height: 40,
    width: 40,
    background: theme.palette.grey[200],
    borderRadius: theme.roundness,
    color: theme.palette.dark.main,
}));

const ImageStyled = styled(ResponsiveImage)(({ theme }) => ({
    borderRadius: theme.roundness,
    overflow: "hidden",
    img: {
        display: "block",
    },
}));

const SearchContainer = styled("div")(({ theme }) => ({
    background: theme.palette.light.main,
}));

const LinkStyled = styled(Link)({
    textDecorationLine: "none",
});
