import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { IconButtonProps } from "@mui/material";
import { ExtendButtonBase } from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import { IconButtonTypeMap } from "@mui/material/IconButton";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import { ComponentType } from "react";
import { ElementOf } from "ts-essentials";

import { TikTokIcon } from "./Icon/TikTok";

export const SocialLinks = styled("div")({});

export const SocialLinkTypeValues = [
    "twitter",
    "facebook",
    "instagram",
    "linkedin",
    "tikTok",
    "youTube",
    "pinterest",
] as const;
export type SocialLinkType = ElementOf<typeof SocialLinkTypeValues>;

export type SocialLinkProps = {
    type: SocialLinkType;
    href: string;
};

const SocialLinkWrapper = styled(IconButton as ExtendButtonBase<IconButtonTypeMap<IconButtonProps, "a">>)(
    ({ theme }) => ({
        color: "currentColor",
        transition: theme.transitions.create(["color", "background-color"], {
            duration: theme.transitions.duration.short,
        }),
        "&:hover": {
            opacity: 0.7,
        },
    })
);

const IconComponents: Record<SocialLinkType, ComponentType<SvgIconProps>> = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    twitter: TwitterIcon,
    linkedin: LinkedInIcon,
    tikTok: TikTokIcon,
    youTube: YouTubeIcon,
    pinterest: PinterestIcon,
};

export function SocialLink({ type, ...props }: SocialLinkProps) {
    return (
        <SocialLinkWrapper target="_blank" rel="nofollow noopener noreferrer" {...props}>
            <SocialLinkIcon type={type} />
        </SocialLinkWrapper>
    );
}

export type SocialLinkIconProps = {
    type: SocialLinkType;
} & SvgIconProps;

export function SocialLinkIcon({ type, ...props }: SocialLinkIconProps) {
    const IconComponent = IconComponents[type];
    return <IconComponent {...props} />;
}
