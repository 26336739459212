import { Children, forwardRef, ForwardedRef } from "react";

import { ScrollableContainer, ScrollableContainerProps } from "../../components/ScrollableContainer";
import { ScrollableContainerItem } from "../../components/ScrollableContainerItem";

const SLIDE_MIN_WIDTH = (container: HTMLElement) => container.clientWidth * 0.7;

export const HeroThumbnailContainerSlider = forwardRef(function CurationsContainerSlider(
    { children, ...props }: ScrollableContainerProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <ScrollableContainer slideMinWidth={SLIDE_MIN_WIDTH} ref={ref} {...props}>
            {Children.map(children, (node, index) => {
                return <ScrollableContainerItem key={index}>{node}</ScrollableContainerItem>;
            })}
        </ScrollableContainer>
    );
});
