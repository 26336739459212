import { styled } from "@mui/material/styles";
import { CSSProperties } from "react";

export type RawIconDefinition = {
    iconName: string;
    prefix: string;
    width: number;
    height: number;
    path: string;
};
type FontAwesomeIconRaw = {
    definition?: RawIconDefinition;
    style?: Pick<CSSProperties, "color" | "fontSize">;
};
export function FontAwesomeIconRaw(props: FontAwesomeIconRaw) {
    const { definition, style } = props;

    if (!definition) return null;

    const { width, height, path } = definition;

    return (
        <Icon viewBox={`0 0 ${width} ${height}`} fontSize={style?.fontSize} color={style?.color}>
            <path fill="currentColor" d={path} />
        </Icon>
    );
}

const Icon = styled("svg")({
    height: "1em",
    display: "inline-block",
});
