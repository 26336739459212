import { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { ProductCardSkeleton } from "./ProductCard";
import { ProductsContainerGrid } from "./ProductsContainerGrid";

export const ProductsContainerGridSkeleton = forwardRef(function ProductsContainerGridSkeleton(
    { numberOfCards = 3, ...props }: HTMLAttributes<HTMLDivElement> & { numberOfCards?: number },
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <ProductsContainerGrid ref={ref} {...props}>
            {Array.from({ length: numberOfCards }).map((_, index) => (
                <ProductCardSkeleton key={index} />
            ))}
        </ProductsContainerGrid>
    );
});
