import { urlProduct } from "@holibob-packages/url";

import { useEntityConfigurationValueByKey } from "./useEntityConfiguration";

type UseProductUrlGeneratorParams = { curationIdsPath?: string[] };

type ProductUrlIdentifier = { slug?: string | null; id: string };
export type ProductUrlGenerator = ({ slug, id }: ProductUrlIdentifier) => string;

export function useProductUrlGenerator(params?: UseProductUrlGeneratorParams): ProductUrlGenerator {
    const curationIdsPath = params?.curationIdsPath;
    const useSlug = useEntityConfigurationValueByKey<boolean>("SETTING.CAN_USE_SLUG", false);

    return ({ slug, id }: ProductUrlIdentifier) => urlProduct(useSlug ? slug ?? id : id, curationIdsPath);
}
