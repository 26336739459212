import BlockIcon from "@mui/icons-material/Block";

import { LabelValue, LabelValueProps } from "../../components/LabelValue";
import { useProductTranslation } from "../../hooks/useProductTranslation";

export function ProductFreeCancellation(props: Omit<LabelValueProps, "Icon" | "children">) {
    const [t] = useProductTranslation();
    return (
        <LabelValue Icon={BlockIcon} {...props}>
            {t("label.freeCancellation")}
        </LabelValue>
    );
}
