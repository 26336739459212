import { HBMLEventComponentShelfScrolledInput } from "@holibob-packages/consumer-trip-event";

export class HbmlShelfFirstScrollEvent extends Event {
    static NAME = "hbml:shelf-first-scroll";

    constructor(readonly details: HBMLEventComponentShelfScrolledInput["meta"]) {
        super(HbmlShelfFirstScrollEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
