import React, { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { FAQItem } from "../FAQ";
import { HbmlRendererNode } from "../HbmlRenderer";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlFAQItemNode = hbmlNodeFactory<
    typeof HbmlElements.FAQ_ITEM,
    Record<string, unknown>,
    Omit<HTMLAttributes<HTMLDivElement>, "content">
>({
    type: HbmlElements.FAQ_ITEM,
    Component: forwardRef(function ComponentInternal(
        { node: _node, children, ...props },
        ref: ForwardedRef<HTMLDivElement>
    ) {
        return (
            <FAQItem ref={ref} {...props}>
                {children!}
            </FAQItem>
        );
    }),
    // Customizing NodeComponent rendering is necessary!
    // Otherwise Accordion is unable to extract node children and renders everything under AccordionSummary
    NodeComponent({ content }) {
        const itemTitle = content.children[0];
        const itemContent = content.children[1];
        return (
            <FAQItem>
                <HbmlRendererNode node={itemTitle} />
                <HbmlRendererNode node={itemContent} />
            </FAQItem>
        );
    },
});
