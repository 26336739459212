import React, { ComponentType, ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { CurationProductFragment } from "../../gql-request";
import { ProductCardV2, ProductCardV2Props } from "../../product";
import { CurationProductsGridContainer } from "../CurationProducts/CurationProductsGridContainer";
import { CurationProductsSliderContainer } from "../CurationProducts/CurationProductsSlider";
import { CurationProductsSplitScreenContainer } from "../CurationProducts/CurationProductsSplitScreen";
import { HeroWithProductThumbnailsContainer } from "../HeroWithThumbnails/HeroWithProductThumbnailsContainer";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export type HbmlCurationProductsGridNode = {
    mode: "grid";
    parentCurationId?: string;
    maxProducts?: number;
};

export type HbmlCurationProductsSliderNode = {
    mode: "slider";
    parentCurationId?: string;
    maxProducts?: number;
    isOverflowContainer?: boolean;
};

type SplitScreenImagePlacement = "left" | "right";

export type HbmlCurationProductsSplitScreenNode = {
    mode: "split-screen";
    parentCurationId?: string;
    splitScreenImagePlacement?: SplitScreenImagePlacement;
};

export type HbmlCurationProductsHeroNode = {
    mode: "hero";
    parentCurationId?: string;
};

export type HbmlCurationProductsV2NodeAttributes =
    | HbmlCurationProductsGridNode
    | HbmlCurationProductsSliderNode
    | HbmlCurationProductsSplitScreenNode
    | HbmlCurationProductsHeroNode;

export type HbmlCurationProductsMode = HbmlCurationProductsV2NodeAttributes["mode"];

export type HbmlCurationProductsProductHrefFactory = (params: Pick<CurationProductFragment, "slug" | "id">) => string;

export type HbmlCurationProductsV2Props = {
    NoProductsComponent?: ComponentType;
    productHrefFactory: HbmlCurationProductsProductHrefFactory;
    ProductCardComponent?: ComponentType<ProductCardV2Props>;
};

export const HbmlCurationProductsV2Node = hbmlNodeFactory<
    typeof HbmlElements.CURATION_PRODUCTS_V2,
    HbmlCurationProductsV2NodeAttributes,
    HTMLAttributes<HTMLDivElement> & HbmlCurationProductsV2Props
>({
    type: HbmlElements.CURATION_PRODUCTS_V2,
    Component: forwardRef(function ComponentInternal(_props, ref: ForwardedRef<HTMLDivElement>) {
        const { node, ProductCardComponent = ProductCardV2, ...props } = _props;

        if (node.mode === "hero") {
            return <HeroWithProductThumbnailsContainer {...props} {...node} />;
        }

        if (node.mode === "split-screen") {
            return (
                <CurationProductsSplitScreenContainer
                    {...props}
                    {...node}
                    ProductCardComponent={ProductCardComponent}
                />
            );
        }

        if (node.mode === "slider") {
            return (
                <CurationProductsSliderContainer
                    ref={ref}
                    {...props}
                    {...node}
                    mode={node.isOverflowContainer ? "full-width" : "normal"}
                    ProductCardComponent={ProductCardComponent}
                />
            );
        }

        return <CurationProductsGridContainer {...props} {...node} ProductCardComponent={ProductCardComponent} />;
    }),
});
