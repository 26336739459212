import { styled } from "@mui/material/styles";
import React, { ForwardedRef, HTMLAttributes, forwardRef, useEffect } from "react";

import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export type HbmlHTMLNodeAttributes = {
    code?: string;
};
export const HbmlHTMLNode = hbmlNodeFactory<
    typeof HbmlElements.HTML,
    HbmlHTMLNodeAttributes,
    Omit<HTMLAttributes<HTMLDivElement>, "content">
>({
    type: HbmlElements.HTML,
    Component: forwardRef(function ComponentInternal({ node, children, ...props }, ref: ForwardedRef<HTMLDivElement>) {
        return (
            <Wrapper {...props} ref={ref}>
                <HTMLNode code={node.code} />
                {children}
            </Wrapper>
        );
    }),
});

const Wrapper = styled("div")(() => ({
    display: "inline-block",
}));

type HTMLNodeProps = { code?: string };

function HTMLNode({ code }: HTMLNodeProps) {
    const ref = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        const current = ref.current;
        if (!current || !code) return;

        current.innerHTML = code;

        Array.from(current.querySelectorAll("script")).forEach((oldScriptEl) => {
            const newScriptEl = document.createElement("script");

            Array.from(oldScriptEl.attributes).forEach((attr) => {
                newScriptEl.setAttribute(attr.name, attr.value);
            });

            // creating lexical context to avoid global scope pollution and redefinition of existing variables
            const scriptText = document.createTextNode(`{${oldScriptEl.innerHTML}}`);
            newScriptEl.appendChild(scriptText);

            oldScriptEl.parentNode?.replaceChild(newScriptEl, oldScriptEl);
        });
    }, [ref, code]);

    return <div ref={ref} />;
}
