import { Skeleton } from "@mui/material";
import MaterialTab from "@mui/material/Tab";
import MaterialTabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { MarkRequired } from "ts-essentials";

import { CurationContentItemCurationFragment, useCurationListQuery } from "../../apiHooks/graphql";
import { CurationsContainerSkeleton } from "../../curation";
import useIsBreakpoint from "../../hooks/useIsBreakpoint";
import { constants } from "../../style";
import { CurationProductsGridContainer } from "../CurationProducts/CurationProductsGridContainer";
import {
    CurationProductsSliderContainer,
    CurationProductsSliderSkeleton,
} from "../CurationProducts/CurationProductsSlider";
import { CurationProductsGridSkeleton } from "../CurationProductsGridSkeleton";
import { HbmlCurationsTabsNode, HbmlCurationsV2Props } from "../HbmlComponents";
import { DEFAULT_MARKET_PLACE_TILE_HEIGHT } from "../MarketPlace/MarketPlaceTile/MarketPlaceTile";
import { CurationListContainer } from "./CurationListContainer";

type CurationProductsTabsContainerProps = HbmlCurationsTabsNode &
    MarkRequired<HbmlCurationsV2Props, "ProductCardComponent">;

function a11yProps(index: number) {
    return {
        id: `curation-products-tab-${index}`,
        "aria-controls": `curation-products-tabpanel-${index}`,
    };
}

export const CurationTabsContainer = (props: CurationProductsTabsContainerProps) => {
    const { parentCurationId, childrenContentType = "products" } = props;

    const isSmallDevice = useIsBreakpoint("sm");
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const { loading, data } = useCurationListQuery({
        variables: { filter: { parentId: parentCurationId } },
        skip: !parentCurationId,
    });

    const curations = data?.hierarchyCurationList?.nodes ?? [];
    const selectedCuration = curations.length > 0 ? curations[selectedTabIndex] : null;

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setSelectedTabIndex(newValue);
    };

    const isScrollable = isSmallDevice || curations.length > 6;

    return (
        <div>
            <TabsWrapper
                value={selectedTabIndex}
                onChange={handleChange}
                selectionFollowsFocus
                variant={isScrollable ? "scrollable" : "standard"}
                centered={!isScrollable}
                allowScrollButtonsMobile
                style={{ top: props.stickyTopElementsHeight }}
            >
                {loading ? (
                    <TabsSkeleton />
                ) : (
                    curations.map((curation, index) => (
                        <Tab key={curation.id} label={curation.name} {...a11yProps(index)} />
                    ))
                )}
            </TabsWrapper>

            <TabPanel value={selectedTabIndex} index={selectedTabIndex}>
                {childrenContentType === "products" ? (
                    <CurationProductsTabPanel
                        {...props}
                        isCurationListLoading={loading}
                        curations={curations}
                        parentCurationId={selectedCuration?.hierarchyId}
                    />
                ) : (
                    <CurationsTabPanel
                        {...props}
                        isCurationListLoading={loading}
                        curations={curations}
                        parentCurationId={selectedCuration?.hierarchyId}
                    />
                )}
            </TabPanel>
        </div>
    );
};

type CurationTabsProps = CurationProductsTabsContainerProps & {
    loading?: boolean;
    curations: CurationContentItemCurationFragment[];
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

type CurationsTabPanelProps = Omit<CurationTabsProps, "childrenContentType"> & {
    isCurationListLoading?: boolean;
    parentCurationId?: string;
};

function CurationProductsTabPanel(props: CurationsTabPanelProps) {
    const { isCurationListLoading, childrenViewMode, maxItems, NoItemsComponent, ...restProps } = props;

    if (isCurationListLoading) {
        return childrenViewMode === "slider" ? (
            <CurationProductsSliderSkeleton />
        ) : (
            <CurationProductsGridSkeleton maxProducts={maxItems ?? 50} />
        );
    }

    if (childrenViewMode === "slider") {
        return (
            <CurationProductsSliderContainer
                {...restProps}
                mode="normal"
                isOverflowContainer={false}
                maxProducts={maxItems}
                NoProductsComponent={NoItemsComponent}
            />
        );
    }

    return (
        <CurationProductsGridContainer {...restProps} maxProducts={maxItems} NoProductsComponent={NoItemsComponent} />
    );
}

function CurationsTabPanel(props: CurationsTabPanelProps) {
    const { isCurationListLoading, childrenViewMode, parentCurationId, ...restProps } = props;

    if (isCurationListLoading) {
        return (
            <CurationsContainerSkeleton
                ref={null}
                mode={childrenViewMode}
                cardHeight={DEFAULT_MARKET_PLACE_TILE_HEIGHT}
            />
        );
    }

    return (
        <CurationListContainer
            {...restProps}
            mode={childrenViewMode}
            parentId={parentCurationId}
            maxItems={props.maxItems}
        />
    );
}

function TabsSkeleton() {
    return (
        <>
            <TabSkeleton {...a11yProps(0)}>
                <Skeleton variant="text" width={90} height={48} />
            </TabSkeleton>
            <TabSkeleton {...a11yProps(1)}>
                <Skeleton variant="text" width={90} height={48} />
            </TabSkeleton>
            <TabSkeleton {...a11yProps(2)}>
                <Skeleton variant="text" width={90} height={48} />
            </TabSkeleton>
            <TabSkeleton {...a11yProps(3)}>
                <Skeleton variant="text" width={90} height={48} />
            </TabSkeleton>
        </>
    );
}

const TabsWrapper = styled(MaterialTabs)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(0.1),
    paddingBottom: theme.spacing(0.1),
    marginBottom: theme.spacing(2),
    position: "sticky",
    zIndex: 11,
    // Don't remove this background color as it is sticky and without background tabs will not be visible
    background: `var(--section-general-background, ${constants.DEFAULT_BACKGROUND_COLOR})`,
    [theme.breakpoints.up("md")]: {
        position: "initial",
        zIndex: "unset",
    },
    root: { textTransform: "none" },
}));

export const Tab = styled(MaterialTab)(({ theme }) => ({
    textTransform: "none",
    marginRight: theme.spacing(4),
}));

const TabSkeleton = styled(Skeleton)(({ theme }) => ({
    marginRight: theme.spacing(4),
}));
