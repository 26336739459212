import FlashOnIcon from "@mui/icons-material/FlashOn";

import { LabelValue, LabelValueProps } from "../../components/LabelValue";
import { useProductTranslation } from "../../hooks/useProductTranslation";

export function ProductInstantConfirmation(props: Omit<LabelValueProps, "Icon" | "children">) {
    const [t] = useProductTranslation();
    return (
        <LabelValue Icon={FlashOnIcon} {...props}>
            {t("label.instantConfirmation")}
        </LabelValue>
    );
}
