import { styled } from "@mui/material/styles";
import { ForwardedRef, forwardRef, HTMLAttributes } from "react";
import { ElementOf } from "ts-essentials";

import { MainColumnWrapper } from "../../MainColumnWrapper";

export const ContentWidth = ["default", "wide", "full-width"] as const;
export type ContentWidth = ElementOf<typeof ContentWidth>;

type PageLayoutProps = {
    contentWidth?: ContentWidth;
} & HTMLAttributes<HTMLDivElement>;

export const PageLayout = forwardRef(function Component(
    { contentWidth, ...props }: PageLayoutProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    if (contentWidth === "full-width") {
        return <PageWrapper {...props} ref={ref} withVerticalPadding={false} />;
    }

    if (contentWidth === "wide") {
        return (
            <PageWrapper {...props} ref={ref}>
                <MainColumnWrapper size={"wide"}>{props.children}</MainColumnWrapper>
            </PageWrapper>
        );
    }

    return (
        <PageWrapper {...props} ref={ref}>
            <MainColumnWrapper>{props.children}</MainColumnWrapper>
        </PageWrapper>
    );
});

export type PageWrapperProps = {
    withVerticalPadding?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const PageWrapper = forwardRef(function PageContainerInternal(
    { withVerticalPadding = true, style, children, ...props }: PageWrapperProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <Wrapper
            ref={ref}
            style={style}
            data-with-vertical-padding={withVerticalPadding}
            data-has-color-override={!!style?.color}
            {...props}
        >
            {children}
        </Wrapper>
    );
});

export const Wrapper = styled("div")(({ theme }) => ({
    boxSizing: "border-box",
    '&[data-with-vertical-padding="true"]': {
        paddingTop: theme.airiness,
        paddingBottom: theme.airiness,
    },
    '&[data-has-color-override="true"]': {
        " .MuiTypography-root": {
            color: "inherit !important",
        },
    },
    "&::after": {
        content: '""',
        clear: "both",
        display: "block",
    },
}));
