"use client";

// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- It's fine here
import { usePathname as useNextPathname } from "next/navigation";
import { useMemo } from "react";

import { hasPathnamePrefixed } from "./hasPathnamePrefixed";
import { useClientLocale } from "./useClientLocale";

/**
 * Returns the pathname without a potential locale prefix.
 *
 * @example
 * ```tsx
 * 'use client';
 *
 * import { usePathname } from '@holibob-packages/ui-core/navigation';
 *
 * // When the user is on `/en`, this will be `/`
 * const pathname = usePathname();
 * ```
 */
export function usePathname(): string {
    const pathname = useNextPathname();

    const locale = useClientLocale();

    return useMemo(() => {
        if (!pathname || !locale) return pathname!;

        const isPathnamePrefixed = hasPathnamePrefixed(locale, pathname);
        const unlocalizedPathname = isPathnamePrefixed
            ? pathname.replace(new RegExp(`^/${locale}`), "") || "/"
            : pathname;

        return unlocalizedPathname;
    }, [locale, pathname]);
}
