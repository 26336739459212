import MUIButton, { ButtonProps as MUIButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { Link } from "@holibob-packages/ui-core/navigation";
import { makeStyles, withStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()((theme) => ({
    button: {},
    buttonPrimary: {
        color: theme.palette.buttonPrimary.contrastText,
        backgroundColor: theme.palette.buttonPrimary.main,
        "&:hover": {
            color: theme.palette.buttonPrimary.contrastText,
            backgroundColor: theme.palette.buttonPrimary.main,
            opacity: 0.8,
        },
    },
    buttonSecondary: {
        color: theme.palette.buttonSecondary.contrastText,
        backgroundColor: theme.palette.buttonSecondary.main,
        "&:hover": {
            color: theme.palette.buttonSecondary.contrastText,
            backgroundColor: theme.palette.buttonSecondary.main,
            opacity: 0.8,
        },
    },
}));

const CircularProgressStyled = withStyles(CircularProgress, {
    root: {
        color: "inherit",
        marginLeft: 10,
    },
});

export const ButtonLoading = () => <CircularProgressStyled size={20} />;

export type ButtonProps = MUIButtonProps & {
    href?: string;
    download?: boolean;
};
export const Button = ({ className, href, download, children, ...props }: ButtonProps) => {
    const { classes, cx } = useStyles();

    const button = (
        <MUIButton variant="contained" disableElevation {...props} className={cx(classes.button, className)}>
            {children}
        </MUIButton>
    );

    if (href) {
        return (
            <Link download={download} href={href}>
                {button}
            </Link>
        );
    }

    return button;
};

export const ButtonPrimary = ({ className, ...props }: ButtonProps) => {
    const { classes, cx } = useStyles();
    return <Button {...props} className={cx(classes.buttonPrimary, className)} />;
};

export const ButtonSecondary = ({ className, ...props }: ButtonProps) => {
    const { classes, cx } = useStyles();
    return <Button {...props} className={cx(classes.buttonSecondary, className)} />;
};

export default Button;
