import MuiButton, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import React, { ForwardedRef, HTMLAttributes, forwardRef } from "react";

import { NamedColour } from "@holibob-packages/colour";

import { useColourResolver } from "../../hooks";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const DEFAULT_BUTTON_ATTR: Required<Omit<HbmlButtonNodeAttributes, "label">> = {
    size: "medium",
    variant: "contained",
    color: "primary",
    action: {
        url: undefined,
        openInNewTab: false,
    },
};

export type HbmlButtonNodeAttributes = {
    label?: string;
    size?: ButtonProps["size"];
    variant?: ButtonProps["variant"];
    color?: NamedColour;
    action?: {
        url?: string;
        openInNewTab?: boolean;
    };
};

export const HbmlButtonNode = hbmlNodeFactory<
    typeof HbmlElements.BUTTON,
    HbmlButtonNodeAttributes,
    Omit<HTMLAttributes<HTMLAnchorElement>, "content">
>({
    type: HbmlElements.BUTTON,
    Component: forwardRef(function ComponentInternal(
        { node, children, ...props },
        ref: ForwardedRef<HTMLAnchorElement>
    ) {
        const { label, size, variant = DEFAULT_BUTTON_ATTR.variant, color = DEFAULT_BUTTON_ATTR.color, action } = node;

        const colourResolver = useColourResolver();

        if (!action) return null;

        const resolvedColor = colourResolver(color) ?? DEFAULT_BUTTON_ATTR.color;

        const isLightColor = color === "light" || color === "white";

        const style = {
            "--color": resolvedColor,
            ...(isLightColor &&
                variant === "contained" && {
                    "--text-color": "black",
                }),
        } as React.CSSProperties;

        return (
            <StyledButtonLink
                // We are using "any" here because MUI Button can be either a button or an anchor
                // Whilst using props spread it can not dynamically identify the correct type to use
                {...(props as any)}
                ref={ref}
                href={action.url}
                size={size}
                variant={variant}
                target={action.openInNewTab ? "_blank" : "_self"}
                data-button-variant={variant}
                style={style}
            >
                {label}
                {children}
            </StyledButtonLink>
        );
    }),
});

const StyledButtonLink = styled(MuiButton)(({ theme }) => ({
    display: "inline-block",
    textAlign: "center",
    whiteSpace: "nowrap",
    borderRadius: theme.roundness,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: "none",

    '&[data-button-variant="contained"]': {
        backgroundColor: "var(--color)",
        color: `var(--text-color, ${theme.palette.light.main})`,
    },

    '&[data-button-variant="outlined"]': {
        backgroundColor: "transparent",
        color: "var(--color)",
        border: `1px solid var(--color)`,
    },
})) as typeof MuiButton;
