import { styled } from "@mui/material/styles";
import { ImgHTMLAttributes, useCallback } from "react";

import { ResizePosition } from "@holibob-packages/vault";

import { HeroImageWrapper } from "./HeroImageWrapper";
import { defaultSrcFactory, ResponsiveImage, ResponsiveImageSrc } from "./ResponsiveImage";
import { TextSubtitle } from "./Text";

const Wrapper = styled("div")({
    position: "relative",
});
const PictureWrapper = styled(ResponsiveImage)({
    width: "100%",
    display: "block",
});

const SIZES = [300, 450, 600, 900, 1280, 1200, 1536, 2048, 2560, 3440, 3840, 4096];

export function HeroImagePicture({
    src,
    alt,
    smallScreenHeight = 200,
    mediumScreenHeight = 300,
    loading = "lazy",
}: {
    src: ResponsiveImageSrc;
    alt: string;
    smallScreenHeight?: number;
    mediumScreenHeight?: number;
    loading?: "eager" | "lazy";
}) {
    const ImageComponent = useCallback(
        (props: ImgHTMLAttributes<HTMLImageElement>) => {
            return (
                <HeroImageWrapper
                    {...props}
                    smallScreenHeight={smallScreenHeight}
                    mediumScreenHeight={mediumScreenHeight}
                />
            );
        },
        [smallScreenHeight, mediumScreenHeight]
    );
    return (
        <PictureWrapper
            srcFactory={(args) => {
                const vaultUrl = defaultSrcFactory(args);
                if (args.type === "dynamic-width-strict-height" && args.width < 900 * args.resolution) {
                    return vaultUrl.modifyEdits({
                        resize: {
                            ...vaultUrl.edits?.resize,
                            height: smallScreenHeight * args.resolution,
                        },
                    });
                }
                return vaultUrl;
            }}
            src={src}
            srcSetSizes={SIZES}
            sizes="100vw"
            ImageComponent={ImageComponent}
            alt={alt}
            height={mediumScreenHeight}
            defaultResizePosition={ResizePosition.ATTENTION}
            loading={loading}
        />
    );
}

const HeroImageSubtitle = styled(TextSubtitle)(({ theme }) => ({
    position: "absolute",
    bottom: 0,
    right: 0,
    color: theme.palette.common.white,
    backgroundColor: "rgba(0,0,0,0.4)",
    padding: theme.spacing(0.5, 2),
}));

export function HeroImage({
    src,
    name,
    label,
    mediumScreenHeight,
    smallScreenHeight,
    ...restProps
}: {
    name: string;
    src: ResponsiveImageSrc;
    label?: string;
    smallScreenHeight?: number;
    mediumScreenHeight?: number;
}) {
    return (
        <Wrapper {...restProps}>
            <HeroImagePicture
                src={src}
                alt={name}
                mediumScreenHeight={mediumScreenHeight}
                smallScreenHeight={smallScreenHeight}
                loading="eager"
            />
            {label && <HeroImageSubtitle>{label}</HeroImageSubtitle>}
        </Wrapper>
    );
}
