import { CSSProperties } from "react";

import { SpotColourRef } from "@holibob-packages/colour";
import { AssetType, AssetUrl, assetUrlToVaultUrl } from "@holibob-packages/vault";

import { ColourResolver } from "../../../utils/resolveColour";
import { Gradient, gradientToBackground } from "./Gradient";

export type Background = {
    gradient?: Gradient;
    color?: string | SpotColourRef;
    imageVaultId?: string;
};

export function isBackgroundEmpty(background: Background) {
    return !background.gradient && !background.color && !background.imageVaultId;
}

export function getBackgroundStyleProperty(
    background: Background,
    assetType: AssetType,
    colourResolver: ColourResolver
) {
    if (isBackgroundEmpty(background)) {
        return undefined;
    }

    const backgrounds: string[] = [];

    const { gradient, color, imageVaultId } = background;

    if (gradient) {
        const finalGradient = gradientToBackground(gradient, colourResolver);
        if (finalGradient) {
            backgrounds.push(finalGradient);
        }
    }

    if (imageVaultId) {
        const assetUrl = AssetUrl.enforceUrlForIdOrUrl(imageVaultId, {
            type: assetType,
        }).unwrap();
        const url = assetUrlToVaultUrl(assetUrl).toString();
        backgrounds.push(`center / cover no-repeat url(${url})`);
    }

    if (color) {
        const finalColor = colourResolver(color);
        if (finalColor) {
            backgrounds.push(finalColor);
        }
    }
    return backgrounds.join(", ");
}

export function getBackgroundStyle(
    background: Background,
    assetType: AssetType,
    colourResolver: ColourResolver
): CSSProperties | undefined {
    const property = getBackgroundStyleProperty(background, assetType, colourResolver);
    return property ? { background: property } : undefined;
}
