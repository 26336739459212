import { ComponentType } from "react";

import { urlCuration } from "@holibob-packages/url";
import { AssetUrl } from "@holibob-packages/vault";

import { CurationContentItemCurationFragment, useCurationListQuery } from "../../apiHooks/graphql";
import { useNextTranslation } from "../../hooks/useNextTranslation";
import { HeroThumbnail } from "./HeroThumbnail";
import { HeroWithThumbnails } from "./HeroWithThumbnails";

const getCurationData = (curation: CurationContentItemCurationFragment): HeroThumbnail | null => {
    try {
        const { id, slug, name, heroImageAsset } = curation;
        const curationHeroImageId = heroImageAsset?.uri ?? "";
        const href = urlCuration({ curationId: id, slug });
        const imageAssetUrl = AssetUrl.fromString(curationHeroImageId).unwrap();

        return { id, name, imageAssetUrl, href };
    } catch (error) {
        return null;
    }
};

export type HeroWithCurationThumbnailsBaseProps = {
    curationParentId?: string;
    NoItemsComponent?: ComponentType;
};
export type HeroWithCurationThumbnailsProps = HeroWithCurationThumbnailsBaseProps;

export const HeroWithCurationThumbnailsContainer = (props: HeroWithCurationThumbnailsProps) => {
    const { curationParentId: parentId, ...restProps } = props;
    const [t] = useNextTranslation("hbml");
    const { loading, data } = useCurationListQuery({
        variables: { filter: { parentId }, pageSize: 5 },
        skip: !parentId,
    });
    const curations = data?.hierarchyCurationList?.nodes ?? [];
    const thumbnails = curations.map((curation) => getCurationData(curation)).filter((x): x is HeroThumbnail => !!x);

    return (
        <HeroWithThumbnails
            loading={loading}
            thumbnails={thumbnails}
            buttonLabel={t("heroWithThumbnails.seeAllProducts.label")}
            {...restProps}
        />
    );
};
