import { forwardRef, ForwardedRef, HTMLAttributes } from "react";
import { ElementOf } from "ts-essentials";

import { CurationsContainerGrid } from "./CurationsContainerGrid";
import { CurationsContainerGridSkeleton } from "./CurationsContainerGridSkeleton";
import { CurationsContainerSlider, CurationsContainerSliderProps } from "./CurationsContainerSlider";
import { CurationsContainerSliderSkeleton } from "./CurationsContainerSliderSkeleton";

export { CurationsContainerGrid };

export { CurationsContainerGridSkeleton };

export const CurationsContainerModeValues = ["grid", "slider"] as const;
export type CurationsContainerMode = ElementOf<typeof CurationsContainerModeValues>;

export type CurationsContainerProps = {
    mode: CurationsContainerMode;
    isOverflowContainer?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const CurationsContainer = forwardRef(function CurationsContainer(
    { mode, isOverflowContainer = false, ...props }: CurationsContainerProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const Component = getComponentForMode(mode, isOverflowContainer);
    return <Component ref={ref} {...props} />;
});

function getComponentForMode(mode: CurationsContainerMode, isOverflowContainer: boolean) {
    if (mode === "slider") {
        return isOverflowContainer ? CurationContainerSliderFullWidth : CurationsContainerSlider;
    }
    return CurationsContainerGrid;
}

function CurationContainerSliderFullWidth(props: CurationsContainerSliderProps) {
    return <CurationsContainerSlider mode="full-width" {...props} />;
}

export type CurationsContainerSkeletonProps = {
    mode: CurationsContainerMode;
    cardHeight?: number;
} & HTMLAttributes<HTMLDivElement>;

export const CurationsContainerSkeleton = forwardRef(function CurationsContainerSkeleton(
    { mode, ...props }: CurationsContainerSkeletonProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const Component = mode === "grid" ? CurationsContainerGridSkeleton : CurationsContainerSliderSkeleton;
    return <Component ref={ref} {...props} />;
});
