import Backdrop from "@mui/material/Backdrop";
import MuiModal from "@mui/material/Modal";
import { forwardRef } from "react";

import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalContent: {
        backgroundColor: "white",
        border: "1px solid #999",
        position: "relative",
        borderRadius: 10,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        zIndex: 10,
    },
}));

/**
 * @deprecated
 *
 * Please use the Modal components from `@holibob-packages/ui-core/components` instead.
 */
export const Modal = ({ className, children, open, onClose, ...props }: $TSFixMe) => {
    const { classes, cx } = useStyles();

    return (
        <MuiModal
            disableEnforceFocus // This is ABSOLUTELY REQUIRED as without it Stripe 3DS fails
            aria-labelledby="payment-modal-title"
            aria-describedby="payment-modal-description"
            className={cx(classes.modal, className)}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            {...props}
        >
            {children}
        </MuiModal>
    );
};

export const ModalContent = forwardRef(({ className, ...props }: $TSFixMe, ref) => {
    const { classes, cx } = useStyles();
    return <div ref={ref} className={cx(classes.modalContent, className)} {...props} />;
});
ModalContent.displayName = "ModalContent";

export default Modal;
