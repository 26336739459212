import React, { ComponentType, ForwardedRef, forwardRef } from "react";

import { useCurationListWithProductCountQuery } from "../../apiHooks/graphql";
import { CurationsContainerSkeleton } from "../../curation/CurationsContainer";
import { HbmlCurationsGridNode, HbmlCurationsSliderNode } from "../HbmlComponents";
import { DEFAULT_MARKET_PLACE_TILE_HEIGHT } from "../MarketPlace/MarketPlaceTile/MarketPlaceTile";
import { CurationList } from "./CurationList";

type CurationListContainerProps = {
    parentId?: string;
    maxItems?: number;
    isOverflowContainer?: boolean;
    NoItemsComponent?: ComponentType;
    mode: HbmlCurationsGridNode["mode"] | HbmlCurationsSliderNode["mode"];
};

export const CurationListContainer = forwardRef(function Component(
    props: CurationListContainerProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { mode, NoItemsComponent, parentId, maxItems } = props;
    const { loading, data } = useCurationListWithProductCountQuery({
        variables: parentId ? { filter: { parentId }, pageSize: maxItems } : undefined,
        skip: !parentId,
    });
    const curations = data?.hierarchyCurationList?.nodes ?? [];

    if (loading) {
        return <CurationsContainerSkeleton ref={ref} {...props} cardHeight={DEFAULT_MARKET_PLACE_TILE_HEIGHT} />;
    }

    if (curations.length === 0) {
        return NoItemsComponent ? <NoItemsComponent /> : null;
    }

    return <CurationList {...props} curations={curations} mode={mode} />;
});
