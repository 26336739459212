import { TypographyProps } from "@mui/material";
import { CSSProperties } from "react";

import { ColourResolver } from "../../../utils/resolveColour";
import { Background, getBackgroundStyle } from "../common/Background";
import { getResolvedGradientColors } from "../common/Gradient";
import { useSectionPadding } from "./useSectionPadding";

export function useSectionStyles(
    style: CSSProperties | undefined,
    background: Background | undefined,
    colourResolver: ColourResolver,
    align: TypographyProps["align"] | undefined
) {
    const sectionPadding = useSectionPadding(style);
    const finalStyle: CSSProperties = {
        ...style,
        ...sectionPadding,
    };

    if (style?.color) {
        const resolvedColour = colourResolver(style.color);
        if (resolvedColour) {
            finalStyle.color = resolvedColour;
        }
    }
    if (background) {
        const backgroundStyles = getBackgroundStyle(background, "hbmlSectionImage", colourResolver);
        if (backgroundStyles) {
            const resolvedGradientColors = getResolvedGradientColors(background.gradient, colourResolver);

            const sectionGeneralBackground = resolvedGradientColors
                ? background.gradient?.direction == "horizontal"
                    ? resolvedGradientColors.colourA
                    : resolvedGradientColors.colourB
                : background;

            Object.assign(finalStyle, {
                ...backgroundStyles,
                "--section-general-background": sectionGeneralBackground,
            });
        }
    }

    if (align) {
        finalStyle.textAlign = align;
    }

    return finalStyle;
}
