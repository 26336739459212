import type { HbmlElementNode } from "@holibob-packages/hbml";

import { HbmlRendererNodes } from "../HbmlRenderer";
import { MediaObjectMedia } from "../MediaObjectMedia";

export const HbmlMediaObjectMediaNodeComponent = MediaObjectMedia;

export function HbmlMediaObjectMediaNode({ content }: { content: HbmlElementNode }) {
    return (
        <HbmlMediaObjectMediaNodeComponent>
            <HbmlRendererNodes content={content.children} />
        </HbmlMediaObjectMediaNodeComponent>
    );
}
