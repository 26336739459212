import React, { ComponentType, ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { ProductCardV2, ProductCardV2Props } from "../../product";
import { CurationListContainer } from "../Curations/CurationListContainer";
import { CurationTabsContainer } from "../Curations/CurationTabsContainer";
import { HeroWithCurationThumbnailsContainer } from "../HeroWithThumbnails/HeroWithCurationThumbnailsContainer";
import { HbmlCurationProductsProductHrefFactory } from "./HbmlCurationProductsV2Node";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export type HbmlCurationsGridNode = {
    mode: "grid";
    parentCurationId?: string;
    maxItems?: number;
    isOverflowContainer?: boolean;
};

export type HbmlCurationsSliderNode = {
    mode: "slider";
    parentCurationId?: string;
    maxItems?: number;
    isOverflowContainer?: boolean;
};

export type HbmlCurationsHeroNode = {
    mode: "hero";
    parentCurationId?: string;
    maxItems?: number;
};

export type HbmlCurationsTabsNode = {
    mode: "tabs";
    parentCurationId?: string;
    childrenViewMode: "grid" | "slider";
    childrenContentType: "products" | "curations";
    maxItems?: number;
};

export type HbmlCurationChildrenViewMode = HbmlCurationsTabsNode["childrenViewMode"];
export type HbmlCurationChildrenContentType = HbmlCurationsTabsNode["childrenContentType"];

export type HbmlCurationsV2NodeAttributes =
    | HbmlCurationsGridNode
    | HbmlCurationsSliderNode
    | HbmlCurationsHeroNode
    | HbmlCurationsTabsNode;

export type HbmlCurationsMode = HbmlCurationsV2NodeAttributes["mode"];

export type HbmlCurationsV2Props = {
    NoItemsComponent?: ComponentType;
    productHrefFactory: HbmlCurationProductsProductHrefFactory;
    stickyTopElementsHeight?: number;
    ProductCardComponent?: ComponentType<ProductCardV2Props>;
};

export const HbmlCurationsV2Node = hbmlNodeFactory<
    typeof HbmlElements.CURATIONS_V2,
    HbmlCurationsV2NodeAttributes,
    HbmlCurationsV2Props & HTMLAttributes<HTMLDivElement>
>({
    type: HbmlElements.CURATIONS_V2,
    Component: forwardRef(function HbmlCurationsV2NodeInternal(
        { node, ProductCardComponent, ...props },
        ref: ForwardedRef<HTMLDivElement>
    ) {
        if (node.mode === "tabs") {
            return (
                <CurationTabsContainer
                    {...node}
                    {...props}
                    ProductCardComponent={ProductCardComponent ?? ProductCardV2}
                />
            );
        }

        if (node.mode === "hero") {
            return (
                <HeroWithCurationThumbnailsContainer {...node} {...props} curationParentId={node.parentCurationId} />
            );
        }

        return <CurationListContainer ref={ref} {...node} {...props} parentId={node.parentCurationId} />;
    }),
});
