import { HbmlRendererComponents } from "../HbmlRenderer";
import { HbmlBlockquoteNode } from "./HbmlBlockquoteNode";
import { HbmlBulletedListNode } from "./HbmlBulletedListNode";
import { HbmlButtonNode } from "./HbmlButtonNode";
import { HbmlDividerNode } from "./HbmlDividerNode";
import { HbmlElements } from "./HbmlElements";
import { HbmlElfsightNode } from "./HbmlElfsightEmbedNode";
import { HbmlHeaders1Node } from "./HbmlHeader1Node";
import { HbmlHeaders2Node } from "./HbmlHeader2Node";
import { HbmlHeaders3Node } from "./HbmlHeader3Node";
import { HbmlHeroNode } from "./HbmlHeroNode";
import { HbmlIconNode } from "./HbmlIconNode";
import { HbmlLinkNode } from "./HbmlLinkNode";
import { HbmlListItemContentNode } from "./HbmlListItemContentNode";
import { HbmlListItemNode } from "./HbmlListItemNode";
import { HbmlMediaObjectContentNode } from "./HbmlMediaObjectContentNode";
import { HbmlMediaObjectMediaNode } from "./HbmlMediaObjectMediaNode";
import { HbmlMediaObjectNode } from "./HbmlMediaObjectNode";
import { HbmlNumberedListNode } from "./HbmlNumberedListNode";
import { HbmlParagraphNode } from "./HbmlParagraphNode";
import { HbmlRawTextNode } from "./HbmlRawTextNode";
import { HbmlTableCellNode } from "./HbmlTableCellNode";
import { HbmlTableNode } from "./HbmlTableNode";
import { HbmlTableRowNode } from "./HbmlTableRowNode";
import { HbmlTextRawNode } from "./HbmlTextNode";
import { HbmlVaultImageNode } from "./HbmlVaultImageNode";

export const HbmlStandardPreset: HbmlRendererComponents = {
    text: HbmlTextRawNode,
    [HbmlElements.RAW_TEXT]: HbmlRawTextNode,
    [HbmlElements.PARAGRAPH]: HbmlParagraphNode.nodeComponent,
    [HbmlElements.H1]: HbmlHeaders1Node,
    [HbmlElements.H2]: HbmlHeaders2Node,
    [HbmlElements.H3]: HbmlHeaders3Node,
    [HbmlElements.BLOCKQUOTE]: HbmlBlockquoteNode,
    [HbmlElements.DIVIDER]: HbmlDividerNode.nodeComponent,
    [HbmlElements.MEDIA_OBJECT]: HbmlMediaObjectNode,
    [HbmlElements.MEDIA_OBJECT_MEDIA]: HbmlMediaObjectMediaNode,
    [HbmlElements.MEDIA_OBJECT_CONTENT]: HbmlMediaObjectContentNode,
    [HbmlElements.VAULT_IMAGE]: HbmlVaultImageNode.nodeComponent,
    [HbmlElements.NUMBERED_LIST]: HbmlNumberedListNode,
    [HbmlElements.BULLETED_LIST]: HbmlBulletedListNode,
    [HbmlElements.LIST_ITEM]: HbmlListItemNode,
    [HbmlElements.LIST_ITEM_CONTENT]: HbmlListItemContentNode,
    [HbmlElements.ELFSIGHT_EMBED]: HbmlElfsightNode.nodeComponent,
    [HbmlElements.LINK]: HbmlLinkNode.nodeComponent,
    [HbmlElements.BUTTON]: HbmlButtonNode.nodeComponent,
    [HbmlElements.HERO]: HbmlHeroNode.nodeComponent,
    [HbmlElements.TABLE]: HbmlTableNode.nodeComponent,
    [HbmlElements.TABLE_ROW]: HbmlTableRowNode.nodeComponent,
    [HbmlElements.TABLE_CELL]: HbmlTableCellNode.nodeComponent,
    [HbmlElements.ICON]: HbmlIconNode.nodeComponent,
};

export type HbmlStandardPresetTypes = Exclude<keyof typeof HbmlStandardPreset, "text">;

export function extendStandardPreset(components: HbmlRendererComponents) {
    return {
        ...HbmlStandardPreset,
        ...components,
    };
}
