import HeroCarousel, { ThemeHeroCarousel } from "components/HeroCarousel";
import { BookingProductCardV2 } from "containers/BookingProductCardV2";
import { ProductCardNew } from "containers/ProductCardNew";
import { useCurationProductsListViewMode } from "hooks/useCurationProductsListViewMode";
import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";
import { useHeaderConfiguration } from "hooks/useHeaderEntityConfiguration";
import { useProductUrlGenerator } from "hooks/useProductUrlGenerator";
import { useRef } from "react";

import { HbmlElementNode, HbmlTextNode } from "@holibob-packages/hbml";
import {
    HbmlComponents,
    HbmlElementComponentProps,
    HbmlRendererComponents,
    HeroSearchCard,
    HeroVideo,
} from "@holibob-packages/ui-core/components";
import { HbmlShelfFirstScrollEvent } from "@holibob-packages/ui-core/custom-events";
import { useOnlyFirstCall, useStickyTopElementsHeight } from "@holibob-packages/ui-core/hooks";

import { SearchV2 } from "../../containers/SearchV2";
import useIsMobile from "../../hooks/useIsMobile";

export const contentComponentPreset = {
    section: function Section({ content }: Parameters<typeof HbmlComponents.HbmlSectionNode.nodeComponent>[0]) {
        const withSectionFadeUpAnimation =
            useEntityConfigurationValueByKey<boolean>("SETTING.SECTION_FADE_UP_ANIMATION") ?? false;

        return (
            <HbmlComponents.HbmlSectionNode.nodeComponent
                content={content}
                withSectionFadeUpAnimation={withSectionFadeUpAnimation}
            />
        );
    },
    "section-header": HbmlComponents.HbmlSectionHeaderNode,
    stack: HbmlComponents.HbmlStackNode.nodeComponent,
    "stack-item": HbmlComponents.HbmlStackItemNode.nodeComponent,
    hero: ({ content }: HbmlElementComponentProps) => {
        const Component = ((props) => {
            if (props.mediaType === HbmlComponents.HbmlHeroMediaType.VIDEO) {
                return (
                    <HeroVideo
                        {...props}
                        videoConfig={{ autoPlay: true, loop: true, muted: true, playsInline: true }}
                    />
                );
            }

            if (props.images.length > 0) {
                const children = content.children as HbmlElementNode[] | undefined;
                const heroTitle = children?.find((child) => child.type === "hero-title")?.children[0] as
                    | HbmlTextNode
                    | undefined;
                return <HeroCarousel alt={heroTitle?.text} {...props} />;
            }

            return <ThemeHeroCarousel {...props}>{props.children}</ThemeHeroCarousel>;
        }) as HbmlComponents.HbmlHeroNodeInternalComponent;

        return (
            <HbmlComponents.HbmlHeroNode.nodeComponent
                SearchComponent={HeroSearch}
                content={content}
                Component={Component}
            />
        );
    },
    [HbmlComponents.HbmlElements.UPDATE_COOKIE_CONSENT]: ({ content }: HbmlElementComponentProps) => {
        return (
            <HbmlComponents.HbmlUpdateCookieConsentNode.nodeComponent
                content={content}
                onUpdateCookieConsentClick={() => window.klaro?.show()}
            />
        );
    },
    [HbmlComponents.HbmlElements.HERO_TITLE]: HbmlComponents.HbmlHeroTitleNode.nodeComponent,
    [HbmlComponents.HbmlElements.HERO_SUBTITLE]: HbmlComponents.HbmlHeroSubtitleNode.nodeComponent,
    [HbmlComponents.HbmlElements.HERO_DESCRIPTION]: HbmlComponents.HbmlHeroDescriptionNode.nodeComponent,
    [HbmlComponents.HbmlElements.CURATIONS_V2]: ({ content }: HbmlElementComponentProps) => {
        const productHrefFactory = useProductUrlGenerator();
        const appHeaderConfig = useHeaderConfiguration();
        const stickyTopElementsHeight = useStickyTopElementsHeight(appHeaderConfig.isSticky);

        return (
            <HbmlComponents.HbmlCurationsV2Node.nodeComponent
                content={content}
                ProductCardComponent={BookingProductCardV2}
                productHrefFactory={productHrefFactory}
                stickyTopElementsHeight={stickyTopElementsHeight}
            />
        );
    },
    [HbmlComponents.HbmlElements.CURATION_PRODUCTS]: ({ content }: HbmlElementComponentProps) => {
        const displayMode = useCurationProductsListViewMode();
        const productHrefFactory = useProductUrlGenerator();
        const ref = useRef<HTMLDivElement>(null);
        const onFirstScroll = useOnlyFirstCall(() => {
            ref.current?.dispatchEvent(new HbmlShelfFirstScrollEvent({ curationId: content.parentCurationId }));
        });

        return (
            <HbmlComponents.HbmlCurationProductsNode.nodeComponent
                ProductCardComponent={ProductCardNew}
                productHrefFactory={productHrefFactory}
                content={content}
                mode={displayMode}
                onScroll={onFirstScroll}
                innerRef={ref}
            />
        );
    },
    [HbmlComponents.HbmlElements.CURATION_PRODUCTS_V2]: ({ content }: HbmlElementComponentProps) => {
        const productHrefFactory = useProductUrlGenerator();
        const ref = useRef<HTMLDivElement>(null);
        const onFirstScroll = useOnlyFirstCall(() => {
            ref.current?.dispatchEvent(new HbmlShelfFirstScrollEvent({ curationId: content.parentCurationId }));
        });

        return (
            <HbmlComponents.HbmlCurationProductsV2Node.nodeComponent
                ProductCardComponent={BookingProductCardV2}
                productHrefFactory={productHrefFactory}
                content={content}
                onScroll={onFirstScroll}
                innerRef={ref}
            />
        );
    },
    [HbmlComponents.HbmlElements.SOCIAL_LINKS]: HbmlComponents.HbmlSocialLinksNode.nodeComponent,
    [HbmlComponents.HbmlElements.FAQ]: HbmlComponents.HbmlFAQNode.nodeComponent,
    [HbmlComponents.HbmlElements.FAQ_ITEM]: HbmlComponents.HbmlFAQItemNode.nodeComponent,
    [HbmlComponents.HbmlElements.FAQ_ITEM_TITLE]: HbmlComponents.HbmlFAQItemTitleNode.nodeComponent,
    [HbmlComponents.HbmlElements.FAQ_ITEM_CONTENT]: HbmlComponents.HbmlFAQItemContentNode.nodeComponent,
    [HbmlComponents.HbmlElements.HTML]: HbmlComponents.HbmlHTMLNode.nodeComponent,
    [HbmlComponents.HbmlElements.POWERED_BY_HOLIBOB]: HbmlComponents.HbmlPoweredByHolibobNode.nodeComponent,
} satisfies HbmlRendererComponents;

export const hbmlContentComponentPreset = HbmlComponents.extendStandardPreset(contentComponentPreset);

function HeroSearch() {
    const isMobile = useIsMobile();
    return (
        <HeroSearchCard>
            <SearchV2 autofocus={!isMobile} showSearchButton />
        </HeroSearchCard>
    );
}
