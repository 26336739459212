import { TypographyProps } from "@mui/material";
import { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { HeroImagesSubtitle } from "../HeroImages";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlHeroSubtitleNode = hbmlNodeFactory<
    typeof HbmlElements.HERO_SUBTITLE,
    { align?: TypographyProps["align"] },
    Omit<HTMLAttributes<HTMLHeadingElement>, "color" | "content">
>({
    type: HbmlElements.HERO_SUBTITLE,
    Component: forwardRef(function ComponentInternal({ node, ...props }, ref: ForwardedRef<HTMLDivElement>) {
        return <HeroImagesSubtitle style={{ textAlign: node.align }} ref={ref} {...props} />;
    }),
});
