import { styled } from "@mui/material/styles";
import { ImgHTMLAttributes } from "react";

import { CSSVariable } from "../utils/CSSVariable";

const HEIGHT_SMALL_SCREEN_VARIABLE = new CSSVariable("--hero-image-small-screen-height");
const HEIGHT_MEDIUM_SCREEN_VARIABLE = new CSSVariable("--hero-image-medium-screen-height");
const Img = styled("img")(({ theme }) => ({
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    height: HEIGHT_SMALL_SCREEN_VARIABLE.reference,
    [theme.breakpoints.up("md")]: {
        height: HEIGHT_MEDIUM_SCREEN_VARIABLE.reference,
    },
}));

export type HeroImageWrapperProps = ImgHTMLAttributes<HTMLImageElement> & {
    smallScreenHeight?: number;
    mediumScreenHeight?: number;
};

const DEFAULT_MEDIUM_SCREEN_HEIGHT = 300;
const DEFAULT_SMALL_SCREEN_HEIGHT = 200;

export function HeroImageWrapper({
    style,
    smallScreenHeight = DEFAULT_SMALL_SCREEN_HEIGHT,
    mediumScreenHeight = DEFAULT_MEDIUM_SCREEN_HEIGHT,
    height: _height, // height is purposely ignored
    ...props
}: HeroImageWrapperProps) {
    const finalStyle = {
        ...style,
        [HEIGHT_SMALL_SCREEN_VARIABLE.toString()]: smallScreenHeight + "px",
        [HEIGHT_MEDIUM_SCREEN_VARIABLE.toString()]: mediumScreenHeight + "px",
    };
    return <Img sizes="100vw" style={finalStyle} height={smallScreenHeight} {...props} />;
}
