import { useEffect, useState } from "react";

function useWindowSize() {
    const isClient = typeof window !== "undefined";

    const initialWidth = isClient ? window.innerWidth : undefined;
    const initialHeight = isClient ? window.innerHeight : undefined;

    const [windowSize, setWindowSize] = useState({
        width: initialWidth,
        height: initialHeight,
    });

    useEffect(() => {
        if (!isClient) return;

        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, [isClient]);

    return windowSize;
}

export default useWindowSize;
