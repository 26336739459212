import { styled } from "@mui/material/styles";

export const SectionHeaderContent = styled("div")(({ theme }) => ({
    "&:empty": {
        display: "none",
    },
    "&:not(:last-child)": {
        marginBottom: theme.spacing(2),
    },
}));
