import { styled } from "@mui/material/styles";
import { CSSProperties, HTMLAttributes } from "react";

import { useTextDirection } from "../hooks/useTextDirection";

export type RibbonProps = {
    color?: string;
} & HTMLAttributes<HTMLSpanElement>;

const Wrapper = styled("span")(({ dir, theme }) => ({
    "--ribbon-rotation": "-45deg",
    margin: 0,
    background: "var(--ribbon-color)",
    color: "white",
    position: "absolute",
    test: dir,
    top: 0,
    right: 0,
    transform: "translateX(30%) translateY(0%) rotate(var(--ribbon-rotation))",
    fontFamily: theme.typography.fontFamily,
    lineHeight: 1.4,
    transformOrigin: "top left",
    '&[data-direction="ltr"]': {
        "--ribbon-rotation": "45deg",
    },
    "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        margin: "0 -1px",
        width: "100%",
        height: "100%",
        background: "var(--ribbon-color)",
        left: "100%",
    },
    "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        margin: "0 -1px",
        width: "100%",
        height: "100%",
        background: "var(--ribbon-color)",
        right: "100%",
    },
}));

export function Ribbon({ color = "yellow", children, ...props }: RibbonProps) {
    const style = {
        "--ribbon-color": color,
    } as CSSProperties;
    const direction = useTextDirection();
    return (
        <Wrapper data-direction={direction} style={style} {...props}>
            <span>{children}</span>
        </Wrapper>
    );
}
