import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { HTMLAttributes } from "react";

import { useNextTranslation } from "../../hooks";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlUpdateCookieConsentNode = hbmlNodeFactory<
    typeof HbmlElements.UPDATE_COOKIE_CONSENT,
    Record<string, unknown>,
    Omit<HTMLAttributes<HTMLDivElement>, "content"> & {
        onUpdateCookieConsentClick?: () => void;
    }
>({
    type: HbmlElements.UPDATE_COOKIE_CONSENT,
    Component: function HbmlUpdateCookieConsentNodeInternal({ onUpdateCookieConsentClick, children }) {
        const [t] = useNextTranslation("general");
        return (
            <ButtonStyled variant="text" onClick={onUpdateCookieConsentClick}>
                {t("updateCookieConsent")}
                {children}
            </ButtonStyled>
        );
    },
});

const ButtonStyled = styled(Button)(({ theme }) => ({
    padding: theme.spacing(2, 0),
    color: theme.palette.footer.contrastText,
}));
