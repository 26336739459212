import { styled } from "@mui/material/styles";

import { AssetUrl } from "@holibob-packages/vault";

import { LinkProps } from "../../../navigation/Link";
import { HoverableCardV2 } from "../../HoverableCard";
import { ImageTile, ImageTileOverlayFooter, ImageTileOverlayHeader } from "../../ImageTile";
import { Typography } from "../../Typography";

export const DEFAULT_MARKET_PLACE_TILE_HEIGHT = 333.33;

export type MarketPlaceTileProps = {
    imageAssetUrl: AssetUrl;
    title?: string;
    subTitle?: string;
    height?: number;
    maxWidth?: number;
} & LinkProps;

export type MarketPlaceTileBProps = MarketPlaceTileProps & {
    footerComponent?: React.ReactNode;
};

export const MarketPlaceTileB = ({
    title,
    subTitle,
    imageAssetUrl,
    footerComponent,
    height = DEFAULT_MARKET_PLACE_TILE_HEIGHT,
    maxWidth = 250,
}: MarketPlaceTileBProps) => {
    return (
        <HoverableCardV2 style={{ maxWidth }}>
            <ImageTile
                imageAssetUrl={imageAssetUrl}
                alt={title ?? "item-image"}
                height={height}
                maxWidth={maxWidth}
                hoverOpacity={0.8}
                hoverZoomScale={1.1}
            >
                {title && (
                    <ImageTileOverlayHeader>
                        <MarketPlaceTileOverlayTitle title={title} subTitle={subTitle} />
                    </ImageTileOverlayHeader>
                )}
                {footerComponent && <ImageTileOverlayFooter>{footerComponent}</ImageTileOverlayFooter>}
            </ImageTile>
        </HoverableCardV2>
    );
};

type MarketPlaceTileOverlayTitleProps = {
    title: string;
    subTitle?: string;
};

export const MarketPlaceTileOverlayTitle = ({ title, subTitle }: MarketPlaceTileOverlayTitleProps) => {
    return (
        <MarketPlaceTileOverlayTitleWrapper>
            <MarketPlaceTileOverlayTitleText variant="headline" size="small" color="light">
                {title}
            </MarketPlaceTileOverlayTitleText>
            {subTitle && (
                <Typography variant="body" size="medium" color="light">
                    {subTitle}
                </Typography>
            )}
        </MarketPlaceTileOverlayTitleWrapper>
    );
};

const MarketPlaceTileOverlayTitleWrapper = styled("div")(({ theme }) => ({ padding: theme.spacing(2) }));

const MarketPlaceTileOverlayTitleText = styled(Typography)({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
});
