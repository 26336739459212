import { useAppState } from "hooks/useAppState";
import { useEffect } from "react";

export const useLoading = (loadingProp = false) => {
    const [state, dispatch] = useAppState();

    useEffect(() => {
        dispatch({
            type: loadingProp ? "LOADING_START" : "LOADING_STOP",
        });
    }, [loadingProp, dispatch]);

    return state.loading;
};

export default useLoading;
