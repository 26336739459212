import { hbmlContentComponentPreset } from "components/Hbml/hbmlContentComponentPreset";

import type { Hbml } from "@holibob-packages/hbml";
import { HbmlRenderer, HbmlRendererComponents } from "@holibob-packages/ui-core/components";

export type HbmlContentRendererParams = {
    content: Hbml;
    hbmlComponentsPreset?: HbmlRendererComponents;
};

export function HbmlContentRenderer({
    content,
    hbmlComponentsPreset = hbmlContentComponentPreset,
}: HbmlContentRendererParams) {
    return <HbmlRenderer content={content} components={hbmlComponentsPreset} />;
}
