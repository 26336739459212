import { IconButtonProps, Tooltip } from "@mui/material";

import { useNextTranslation } from "../hooks";
import { CopyIconButton } from "./IconButtons/CopyIconButton";

export type CopyProps = {
    value: string;
    label?: string;
} & Omit<IconButtonProps, "onClick">;

export function Copy({ label, value, ...props }: CopyProps) {
    const [t] = useNextTranslation("general");

    const copyTextToClipboard = () => {
        void navigator.clipboard.writeText(value);
    };

    const copyLabel = t("copy.label");
    const tooltipTitle = `${copyLabel}: ${label ?? value}`;

    return (
        <Tooltip title={tooltipTitle} placement="top">
            <CopyIconButton onClick={copyTextToClipboard} {...props} />
        </Tooltip>
    );
}
