import { styled } from "@mui/material/styles";

import { AssetUrl } from "@holibob-packages/vault";

import { HoverableCardV2 } from "../HoverableCard";
import { ImageTile } from "../ImageTile";

const HeroThumbnailHoverable = styled(HoverableCardV2)(({ theme }) => ({
    "&::after": {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.3)",
        transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeOut,
        }),
    },
    '&[data-active="true"]::after, &:hover::after': {
        opacity: 0,
        boxShadow: "none",
    },
}));

export type HeroThumbnail = { id: string; name: string; imageAssetUrl: AssetUrl; href: string };

type HeroThumbnailProps = {
    thumbnail: HeroThumbnail;
    onSelect: () => void;
    height: number;
    maxWidth: number;
};
export const HeroThumbnail = (props: HeroThumbnailProps) => {
    const { thumbnail, height, maxWidth, onSelect, ...restProps } = props;

    return (
        <HeroThumbnailHoverable onClick={onSelect} {...restProps}>
            <ImageTile
                imageAssetUrl={thumbnail.imageAssetUrl}
                alt={thumbnail.name}
                height={height}
                maxWidth={maxWidth}
                hoverZoomScale={1.1}
            />
        </HeroThumbnailHoverable>
    );
};
