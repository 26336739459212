import { ProductItem } from "../types";

export class CurationProductClickEvent extends Event {
    static NAME = "curation:product-click";

    constructor(
        readonly details: {
            item: ProductItem;
            position: number;
        }
    ) {
        super(CurationProductClickEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
