import { styled } from "@mui/material/styles";

export const ProductsContainerGrid = styled("div")(({ theme }) => ({
    display: "grid",
    gap: theme.airiness,
    gridTemplateColumns: "1fr",
    justifyItems: "center",
    [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "repeat(2, minmax(250px, 1fr))",
    },
    [theme.breakpoints.up("md")]: {
        gridTemplateColumns: "repeat(3, minmax(250px, 1fr))",
    },
}));
