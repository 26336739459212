import { ElementOf } from "ts-essentials";

import { SpotColourRef } from "@holibob-packages/colour";

import { ColourResolver } from "../../../utils/resolveColour";

export type Gradient = {
    colorA: string | SpotColourRef;
    colorB: string | SpotColourRef;
    direction: GradientDirection;
};

export type GradientDirection = ElementOf<typeof GradientDirectionValues>;

export const GradientDirectionValues = ["horizontal", "vertical"] as const;

export function gradientToBackground(gradient: Gradient, colourResolver: ColourResolver) {
    const resolvedColors = getResolvedGradientColors(gradient, colourResolver);

    if (!resolvedColors) return;

    const { colourA, colourB } = resolvedColors;

    return `linear-gradient(${gradient.direction === "vertical" ? "0deg" : "90deg"}, ${colourA}, ${colourB})`;
}

export function getResolvedGradientColors(gradient: Gradient | undefined, colourResolver: ColourResolver) {
    if (!gradient) return undefined;

    const colourA = gradient.colorA ? colourResolver(gradient.colorA) : undefined;
    const colourB = gradient.colorB ? colourResolver(gradient.colorB) : undefined;

    if (!colourA || !colourB) return undefined;

    return { colourA, colourB };
}
