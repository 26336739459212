import { styled } from "@mui/material/styles";
import { CSSProperties, ForwardedRef, forwardRef, HTMLAttributes } from "react";
import { ElementOf } from "ts-essentials";

import { useColourResolver } from "../hooks";
import { ColourResolver } from "../utils/resolveColour";
import { MediaObjectPlacement } from "./MediaObjectPlacement";

const MediaObjectWrapper = styled("div")(({ theme }) => ({
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateAreas: '"media" "content"',
    "--media-vertical-placement": "start",
    '&[data-vertical-placement="center"]': {
        "--media-vertical-placement": "center",
    },
    [theme.breakpoints.up("sm")]: {
        '&[data-placement="left"]': {
            gridTemplateAreas: '"media content"',
            gridTemplateColumns: "min-content auto",
        },
        '&[data-placement="top"]': {
            "--media-alignment": "center",
            gridTemplateAreas: '"media" "content"',
        },
        '&[data-placement="right"]': {
            gridTemplateAreas: '"content media"',
            gridTemplateColumns: "auto min-content",
        },
    },
}));

export type MediaObjectVerticalPlacement = ElementOf<typeof MediaObjectVerticalPlacementValues>;
export const MediaObjectVerticalPlacementValues = ["top", "center"] as const;

export type MediaObjectProps = {
    placement?: MediaObjectPlacement;
    verticalPlacement?: MediaObjectVerticalPlacement;
} & HTMLAttributes<HTMLDivElement>;

export const MediaObject = forwardRef(function MediaObjectInternal(
    { placement = "left", verticalPlacement = "top", style, ...props }: MediaObjectProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const colourResolver = useColourResolver();
    return (
        <MediaObjectWrapper
            data-vertical-placement={verticalPlacement}
            data-placement={placement}
            style={getStyles(style, colourResolver)}
            {...props}
            ref={ref}
        />
    );
});

function getStyles(style: CSSProperties | undefined, colourResolver: ColourResolver) {
    const finalStyle = {
        ...style,
    };
    if (style?.color) {
        const finalColour = colourResolver(style.color);
        if (finalColour) {
            finalStyle.color = finalColour;
        }
    }

    if (style?.backgroundColor) {
        const finalColour = colourResolver(style.backgroundColor);
        if (finalColour) {
            finalStyle.backgroundColor = finalColour;
        }
    }

    return finalStyle;
}
