import { TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { CSSProperties, ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { useColourResolver } from "../../hooks";
import { StackItem } from "../Stack";
import { HbmlElements } from "./HbmlElements";
import { Background, getBackgroundStyle } from "./common/Background";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

const StackItemWrapped = styled(StackItem)({
    overflow: "auto", // clearfix for floating images
    '&[data-has-color-override="true"]': {
        " .MuiTypography-root": {
            color: "inherit !important",
        },
    },
});

export const HbmlStackItemNode = hbmlNodeFactory<
    typeof HbmlElements.STACK_ITEM,
    {
        backgroundImageVaultId?: string;
        align?: TypographyProps["align"];
        background?: Background;
        style?: Pick<CSSProperties, "color">;
    },
    Omit<HTMLAttributes<HTMLDivElement>, "content">
>({
    type: HbmlElements.STACK_ITEM,
    Component: forwardRef(function ComponentInternal({ node, ...props }, ref: ForwardedRef<HTMLDivElement>) {
        const { background, align, style } = node;

        const colourResolver = useColourResolver();
        const finalStyle: CSSProperties = {
            ...style,
            textAlign: align,
            color: style?.color ? colourResolver(style.color) : undefined,
            ...(background ? getBackgroundStyle(background, "hbmlStackItemImage", colourResolver) : undefined),
        };

        return (
            <StackItemWrapped data-has-color-override={!!finalStyle.color} style={finalStyle} {...props} ref={ref} />
        );
    }),
});
