import { forwardRef, ForwardedRef, HTMLAttributes } from "react";

import { CurationCardSkeleton } from "./CurationCard";
import { CurationsContainerGrid } from "./CurationsContainerGrid";

type CurationsContainerGridSkeletonProps = { cardHeight?: number } & HTMLAttributes<HTMLDivElement>;
export const CurationsContainerGridSkeleton = forwardRef(function Component(
    { cardHeight, ...props }: CurationsContainerGridSkeletonProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <CurationsContainerGrid ref={ref} {...props}>
            <CurationCardSkeleton height={cardHeight} />
            <CurationCardSkeleton height={cardHeight} />
            <CurationCardSkeleton height={cardHeight} />
        </CurationsContainerGrid>
    );
});
