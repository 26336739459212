import { HTMLAttributes, ForwardedRef, forwardRef } from "react";
import { ElementOf } from "ts-essentials";

import { ProductsContainerGridSkeleton } from "./ProductsContainerGridSkeleton";
import { ProductsContainerSliderSkeleton } from "./ProductsContainerSliderSkeleton";

export const ProductsContainerModeValues = ["grid", "slider"] as const;
export type ProductsContainerMode = ElementOf<typeof ProductsContainerModeValues>;

export type ProductsContainerProps = {
    mode: ProductsContainerMode;
} & HTMLAttributes<HTMLDivElement>;

export const ProductsContainerSkeleton = forwardRef(function ProductsContainerSkeleton(
    { mode, ...props }: ProductsContainerProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const Component = mode === "slider" ? ProductsContainerSliderSkeleton : ProductsContainerGridSkeleton;
    return <Component {...props} ref={ref} />;
});
