import { TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CSSProperties, ForwardedRef, forwardRef, FunctionComponent, HTMLAttributes } from "react";
import { ElementOf, ValueOf } from "ts-essentials";

import type { HbmlElementNode, HbmlNode } from "@holibob-packages/hbml";

import { HbmlRendererNodes } from "../../HbmlRenderer";
import { TypographyDocument } from "../../TypographyDocument";

export type HbmlHeaderLevel = ElementOf<typeof HbmlHeaderLevelValues>;
export const HbmlHeaderLevelValues = [1, 2, 3] as const;

const headerMap = {
    1: "heading-one",
    2: "heading-two",
    3: "heading-three",
} as const;

export type HbmlHeaderNodeAttributesType = {
    align?: TypographyProps["align"];
};
export type HbmlHeaderNodeType = HbmlElementNode & HbmlHeaderNodeAttributesType;

export function getHbmlHeaderNodeTypeForLevel(level: HbmlHeaderLevel): ValueOf<typeof headerMap> {
    return headerMap[level];
}

export type HbmlHeaderNodeProps = {
    content: HbmlHeaderNodeType;
} & Omit<HTMLAttributes<HTMLElement>, "content">;

export function hbmlHeaderNodeComponentFactory<T extends HbmlHeaderLevel>(level: T) {
    const func = forwardRef(
        ({ content, style, ...props }: HbmlHeaderNodeProps, ref: ForwardedRef<HTMLHeadingElement>) => {
            const finalStyle: CSSProperties = {
                ...style,
                ...(content.align && { textAlign: content.align }),
            };

            const variant = `h${level}` as const;

            return <Header variant={variant} style={finalStyle} {...props} ref={ref} />;
        }
    );

    func.displayName = `HbmlH${level}Raw`;
    return func;
}

const Header = styled(TypographyDocument)({
    "h1&:last-child, h2&:last-child, h3&:last-child": {
        marginBlockEnd: "unset",
    },
});

export function createHbmlHeadersNodeComponents<T extends HbmlHeaderLevel>(level: T) {
    const Component = hbmlHeaderNodeComponentFactory(level);
    const func: FunctionComponent<HbmlHeaderNodeProps> = ({ content }) => {
        return (
            <Component content={content}>
                <HbmlRendererNodes content={content.children as HbmlNode[]} />
            </Component>
        );
    };
    func.displayName = `HbmlH${level}`;
    return func;
}
