import React, { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { FAQItemContent } from "../FAQ";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlFAQItemContentNode = hbmlNodeFactory<
    typeof HbmlElements.FAQ_ITEM_CONTENT,
    Record<string, unknown>,
    Omit<HTMLAttributes<HTMLDivElement>, "content">
>({
    type: HbmlElements.FAQ_ITEM_CONTENT,
    Component: forwardRef(function ComponentInternal(
        { node: _node, children, ...props },
        ref: ForwardedRef<HTMLDivElement>
    ) {
        return (
            <FAQItemContent ref={ref} {...props}>
                {children!}
            </FAQItemContent>
        );
    }),
});
