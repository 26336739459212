import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";

const ChildrenWrapper = styled("div")({
    zIndex: 30,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
});

const ContentWrapper = styled(Container)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(2),
}));

export const HbmlChildrenWrapperOverlay = ({ children }: { children: React.ReactNode }) => {
    return (
        <ChildrenWrapper data-ref="heroCarouselOverlay">
            <ContentWrapper>{children}</ContentWrapper>
        </ChildrenWrapper>
    );
};
