import React, { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { FAQItemTitle } from "../FAQ";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlFAQItemTitleNode = hbmlNodeFactory<
    typeof HbmlElements.FAQ_ITEM_TITLE,
    Record<string, unknown>,
    Omit<HTMLAttributes<HTMLDivElement>, "content">
>({
    type: HbmlElements.FAQ_ITEM_TITLE,
    Component: forwardRef(function ComponentInternal(
        { node: _node, children, ...props },
        ref: ForwardedRef<HTMLDivElement>
    ) {
        return (
            <FAQItemTitle ref={ref} {...props}>
                {children!}
            </FAQItemTitle>
        );
    }),
});
