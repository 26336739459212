import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButtonProps } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { forwardRef } from "react";

type CopyIconButtonProps = {
    onClick: () => void;
} & Omit<IconButtonProps, "onClick">;

export const CopyIconButton = forwardRef<HTMLButtonElement, CopyIconButtonProps>(function CopyImageButton(
    { size, onClick, ...iconProps },
    ref
) {
    const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onClick();
    };

    return (
        <IconButton ref={ref} {...iconProps} onClick={onClickHandler}>
            <ContentCopyIcon fontSize={size} />
        </IconButton>
    );
});
