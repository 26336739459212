import { useCallback, useState } from "react";

export function useOnlyFirstCall<T extends (...args: unknown[]) => void>(fn: T) {
    const [functionCalled, setFunctionCalled] = useState(false);
    return useCallback(
        (...args: Parameters<T>) => {
            if (!functionCalled) {
                setFunctionCalled(true);
                fn(...args);
            }
        },
        [functionCalled, setFunctionCalled, fn]
    );
}
