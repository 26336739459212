import { useNextTranslation } from "./useNextTranslation";

/**
 * @FYI Use only inside of the ui-core, if you need direction on hub-web or booking-web use LanguageContext
 */
export function useTextDirection() {
    const { i18n } = useNextTranslation();

    return i18n.dir();
}
