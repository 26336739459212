import { styled } from "@mui/material/styles";
import { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { ProductCardSkeleton } from "./ProductCard";
import { ProductsContainerSlider } from "./ProductsContainerSlider";

const ProductCardSkeletonItem = styled(ProductCardSkeleton)({ width: 250 });
export const ProductsContainerSliderSkeleton = forwardRef(function ProductsContainerSliderSkeleton(
    props: HTMLAttributes<HTMLDivElement>,
    ref: ForwardedRef<HTMLDivElement>
) {
    const skeletons = Array.from({ length: 5 });

    return (
        <ProductsContainerSlider ref={ref} {...props}>
            {skeletons.map((_, index) => (
                <ProductCardSkeletonItem key={index} />
            ))}
        </ProductsContainerSlider>
    );
});
