import { HbmlElementNode } from "@holibob-packages/hbml";

import { HbmlRendererNodes } from "../HbmlRenderer";
import { MediaObjectContent } from "../MediaObjectContent";

export const HbmlMediaObjectContentNodeComponent = MediaObjectContent;

export function HbmlMediaObjectContentNode({ content }: { content: HbmlElementNode }) {
    return (
        <HbmlMediaObjectContentNodeComponent>
            <HbmlRendererNodes content={content.children} />
        </HbmlMediaObjectContentNodeComponent>
    );
}
