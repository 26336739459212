import { styled } from "@mui/material/styles";
import React, { ComponentType, ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { CurationProductFragment, useCurationProductsQuery } from "../../apiHooks/graphql";
import { CurationProductClickEvent } from "../../custom-events";
import { ProductsContainerMode } from "../../product";
import {
    ProductCard,
    ProductCardProductInfo,
    ProductCardProps,
    ProductsContainerSkeleton,
} from "../../product/ProductCard";
import { ProductsContainerGrid } from "../../product/ProductsContainerGrid";
import { ProductsContainerSlider } from "../../product/ProductsContainerSlider";
import { ProductItem } from "../../types";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

type HbmlCurationProductsProductHrefFactory = (params: Pick<CurationProductFragment, "slug" | "id">) => string;

export type HbmlCurationProductsNodeAttributes = {
    parentCurationId?: string;
    mode?: ProductsContainerMode;
};
export const HbmlCurationProductsNode = hbmlNodeFactory<
    typeof HbmlElements.CURATION_PRODUCTS,
    HbmlCurationProductsNodeAttributes,
    {
        NoProductsComponent?: ComponentType;
        productHrefFactory: HbmlCurationProductsProductHrefFactory;
        ProductCardComponent?: ComponentType<ProductCardProps>;
        mode?: ProductsContainerMode;
    } & Omit<HTMLAttributes<HTMLDivElement>, "content">
>({
    type: HbmlElements.CURATION_PRODUCTS,
    Component: forwardRef(function HbmlCurationProductsNodeInternal(
        { node, NoProductsComponent, productHrefFactory, ProductCardComponent = ProductCard, mode, ...props },
        ref: ForwardedRef<HTMLDivElement>
    ) {
        const finalMode = node.mode ?? mode ?? "slider";

        const { loading, data } = useCurationProductsQuery({
            variables: {
                curationId: node.parentCurationId!,
                pageSize: 50,
            },
            skip: !node.parentCurationId,
        });

        const onProductClick = (
            event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
            data: { item: ProductItem; position: number }
        ) => {
            event.target.dispatchEvent(new CurationProductClickEvent(data));
        };

        if (loading) {
            return <ProductsContainerSkeleton {...props} ref={ref} mode={finalMode} />;
        }

        const productList = data?.productList;

        if (!productList?.nodes.length) {
            return NoProductsComponent ? <NoProductsComponent /> : null;
        }

        if (finalMode === "grid") {
            return (
                <ProductsContainerGrid {...props} ref={ref}>
                    {productList.nodes.map((entry, position) => (
                        <ProductCardComponent
                            key={entry.id}
                            showDescription={false}
                            product={entry as ProductCardProductInfo}
                            href={productHrefFactory({ slug: entry.slug, id: entry.id })}
                            onClick={(event, item) => onProductClick(event, { item, position })}
                        />
                    ))}
                </ProductsContainerGrid>
            );
        }

        return (
            <ProductsContainerSlider {...props} ref={ref}>
                {productList.nodes.map((entry, position) => (
                    <SliderProductCardWrapper key={entry.id}>
                        <ProductCardComponent
                            showDescription={false}
                            product={entry as ProductCardProductInfo}
                            href={productHrefFactory({ slug: entry.slug, id: entry.id })}
                            onClick={(event, item) => onProductClick(event, { item, position })}
                        />
                    </SliderProductCardWrapper>
                ))}
            </ProductsContainerSlider>
        );
    }),
});

const SliderProductCardWrapper = styled("div")({ width: 250, height: "100%" });
