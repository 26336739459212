import { useTheme, Theme } from "@mui/material/styles";

import useWindowSize from "./useWindowSize";

type BreakpointName = keyof Theme["breakpoints"]["values"];

export const useIsBreakpoint = (name: BreakpointName) => {
    const theme = useTheme();
    const md = theme.breakpoints.values[name];
    const { width } = useWindowSize();
    return typeof width === "number" ? width < md : false;
};

export default useIsBreakpoint;
