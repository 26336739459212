import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ComponentType, useState } from "react";

import { Link } from "../../navigation/Link";
import { Button } from "../Button";
import { HeroImage } from "../HeroImage";
import { Typography } from "../Typography";
import { HeroThumbnail } from "./HeroThumbnail";
import { HeroThumbnailContainerSlider } from "./HeroThumbnailContainerSlider";

export const DEFAULT_HEIGHT = 680;
export const DEFAULT_HEIGHT_MOBILE = 500;
export const DETAILS_CONTAINER_MAX_WIDTH = 744;
export const DEFAULT_THUMBNAIL_HEIGHT = 126;
export const DEFAULT_THUMBNAIL_HEIGHT_MOBILE = 90;
export const DEFAULT_THUMBNAIL_MAX_WIDTH = 168;
export const DEFAULT_THUMBNAIL_MAX_WIDTH_MOBILE = 120;

const HeroWithThumbnailsWrapper = styled("div")(({ theme }) => ({
    position: "relative",
    width: "100%",
    maxWidth: "100%",
    overflow: "hidden",
    display: "grid",
    height: DEFAULT_HEIGHT_MOBILE,
    [theme.breakpoints.up("md")]: {
        height: DEFAULT_HEIGHT,
    },
    '& [data-active-image="false"]': {
        opacity: 0,
        transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeIn,
        }),
    },
    '& [data-active-image="true"]': {
        opacity: 1,
        transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeOut,
        }),
    },
}));

const HeroWithThumbnailsHeaderWrapper = styled("div")(({ theme }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifySelf: "center",
    top: 192,
    gap: theme.spacing(2),
    maxWidth: DETAILS_CONTAINER_MAX_WIDTH,
    [theme.breakpoints.down("md")]: {
        top: 148,
        maxWidth: "100%",
    },
    padding: "0 16px",
}));

export const HeroWithThumbnailsFooterWrapper = styled("div")({
    display: "flex",
    width: "100%",
    alignItems: "end",
    justifyContent: "center",
    marginBottom: 24,
    overflow: "hidden",
});

export const HeroWithThumbnailsFooterContent = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: "0 16px",
    '& [data-slider="true"]': {
        width: "100%",
    },
}));

const HeroWithThumbnailsTiles = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(3),
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
}));

const HeroWithThumbnailsTitle = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down("md")]: { WebkitLineClamp: 4 },
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
}));

const ImageGradientOverlay = styled("div")({
    position: "absolute",
    background:
        "linear-gradient(to bottom, rgba(0,0,0,0.5) 0, rgba(0,0,0,0.15) 20%,  rgba(0,0,0,0.15) 80%, rgba(0,0,0,0.9) 100%)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
});

const DetailsButton = styled(Button)({
    alignSelf: "flex-start",
});

const HeroWithThumbnailsImage = styled(HeroImage)({
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
});

const SkeletonTitleItem = styled(Skeleton)(({ theme }) => ({
    height: 40,
    maxWidth: DETAILS_CONTAINER_MAX_WIDTH,
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        margin: "0 24px",
    },
}));

const SkeletonThumbnailItem = styled(Skeleton)(({ theme }) => ({
    width: DEFAULT_THUMBNAIL_MAX_WIDTH,
    height: DEFAULT_THUMBNAIL_HEIGHT,
    [theme.breakpoints.down("md")]: {
        maxWidth: DEFAULT_THUMBNAIL_MAX_WIDTH_MOBILE,
        height: DEFAULT_THUMBNAIL_HEIGHT_MOBILE,
    },
}));

export type HeroWithThumbnailsProps = {
    thumbnails: HeroThumbnail[];
    loading?: boolean;
    thumbnailsHeader?: React.ReactElement | undefined;
    buttonLabel: string;
    NoItemsComponent?: ComponentType;
};

const HeroWithProductsThumbnailsSkeleton = (props: HeroWithThumbnailsProps) => {
    return (
        <HeroWithThumbnailsWrapper {...props}>
            <Skeleton variant="rectangular" height="100%" width="100%" style={{ position: "absolute" }} />
            <HeroWithThumbnailsHeaderWrapper style={{ width: "100%" }}>
                {[...Array(3)].map((_, index) => (
                    <SkeletonTitleItem key={index} variant="rectangular" />
                ))}
            </HeroWithThumbnailsHeaderWrapper>
            <HeroWithThumbnailsFooterWrapper>
                <HeroWithThumbnailsTiles>
                    {[...Array(3)].map((_, index) => (
                        <SkeletonThumbnailItem key={index} variant="rectangular" />
                    ))}
                </HeroWithThumbnailsTiles>
            </HeroWithThumbnailsFooterWrapper>
        </HeroWithThumbnailsWrapper>
    );
};

export const HeroWithThumbnails = (props: HeroWithThumbnailsProps) => {
    const { loading, thumbnails, buttonLabel, thumbnailsHeader, NoItemsComponent, ...restProps } = props;
    const [activeThumbnailIndex, setActiveThumbnail] = useState<number>(0);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const thumbnailHeight = isMobile ? DEFAULT_THUMBNAIL_HEIGHT_MOBILE : DEFAULT_THUMBNAIL_HEIGHT;
    const thumbnailMaxWidth = isMobile ? DEFAULT_THUMBNAIL_MAX_WIDTH_MOBILE : DEFAULT_THUMBNAIL_MAX_WIDTH;

    if (loading) {
        return <HeroWithProductsThumbnailsSkeleton {...props} />;
    } else if (thumbnails.length === 0) {
        return NoItemsComponent ? <NoItemsComponent /> : null;
    }

    const activeThumbnail = thumbnails[activeThumbnailIndex];

    return (
        <HeroWithThumbnailsWrapper {...restProps}>
            {thumbnails.map(({ id, imageAssetUrl }, index) => (
                <HeroWithThumbnailsImage
                    key={`${id}_${index}`}
                    src={imageAssetUrl.toString()}
                    mediumScreenHeight={DEFAULT_HEIGHT}
                    smallScreenHeight={DEFAULT_HEIGHT_MOBILE}
                    name="Hero Image"
                    data-active-image={activeThumbnailIndex === index}
                />
            ))}
            <ImageGradientOverlay />
            <HeroWithThumbnailsHeaderWrapper>
                <HeroWithThumbnailsTitle variant="display" size="large" color="light">
                    {activeThumbnail.name}
                </HeroWithThumbnailsTitle>
                <DetailsButton variant="contained" LinkComponent={Link} href={activeThumbnail.href} color="light">
                    {buttonLabel}
                </DetailsButton>
            </HeroWithThumbnailsHeaderWrapper>
            <HeroWithThumbnailsFooterWrapper>
                <HeroWithThumbnailsFooterContent>
                    {thumbnailsHeader}
                    <HeroThumbnailContainerSlider data-slider={true}>
                        {thumbnails.map((thumbnail, index) => (
                            <HeroThumbnail
                                key={`${thumbnail.id}_${index}`}
                                height={thumbnailHeight}
                                maxWidth={thumbnailMaxWidth}
                                thumbnail={thumbnail}
                                onSelect={() => setActiveThumbnail(index)}
                                data-active={activeThumbnailIndex === index}
                            />
                        ))}
                    </HeroThumbnailContainerSlider>
                </HeroWithThumbnailsFooterContent>
            </HeroWithThumbnailsFooterWrapper>
        </HeroWithThumbnailsWrapper>
    );
};
