import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, AccordionSummaryProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useControlledState } from "@react-stately/utils";
import { createContext, ForwardedRef, forwardRef, ReactNode, useContext, useId, useMemo } from "react";

type RootContextShape = {
    expandedId: string | undefined;
    expandAll: boolean;
    onExpandChange: (id: string | undefined) => void;
};

const RootContext = createContext<RootContextShape>({
    expandedId: undefined,
    onExpandChange: () => {
        return;
    },
    expandAll: false,
});

type FAQProps = {
    expandedId?: string;
    defaultExpandedId?: string;
    expandAll?: boolean;
    onExpandChange?: (id: string | undefined) => void;
    children: NonNullable<ReactNode>;
};

export const FAQ = forwardRef(function FAQ(
    { expandedId: expandedIdProp, defaultExpandedId, expandAll = false, onExpandChange, children }: FAQProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const [expandedId, setExpandedId] = useControlledState<string | undefined>(
        expandedIdProp,
        defaultExpandedId,
        (id) => {
            onExpandChange?.(id);
        }
    );
    const context = useMemo((): RootContextShape => {
        return {
            expandedId,
            onExpandChange: setExpandedId,
            expandAll,
        };
    }, [expandedId, setExpandedId, expandAll]);
    return (
        <RootContext.Provider value={context}>
            <div ref={ref}>{children}</div>
        </RootContext.Provider>
    );
});

function useFAQItemState(id: string) {
    const context = useContext(RootContext);

    const isExpandControllable = !context.expandAll;
    return {
        isExpanded: context.expandAll ? true : context.expandedId === id,
        isExpandControllable,
        close: () => {
            isExpandControllable && context.onExpandChange(undefined);
        },
        open: () => {
            isExpandControllable && context.onExpandChange(id);
        },
    };
}

const FAQItemWrapper = styled(Accordion)(({ theme }) => ({
    padding: theme.spacing(2, 3),
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: "solid",
    '&[data-is-expand-controllable="false"]': {
        '[data-ref="faq-item-title"][data-ref="faq-item-title"]': {
            cursor: "default",
            pointerEvents: "none", // This is needed to make it clickable in slate
        },
    },
    "&.Mui-expanded": {
        margin: 0,
    },
    "& + &": {
        borderTop: 0,
    },
}));

export type FAQItemProps = Omit<AccordionProps, "expanded" | "defaultExpanded" | "onChange"> & { id?: string };

export const FAQItem = forwardRef(function FAQItem(
    { id: idProp, ...props }: FAQItemProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const hookId = useId();
    const id = idProp ?? hookId;
    const { isExpanded, close, open, isExpandControllable } = useFAQItemState(id);

    return (
        <FAQItemWrapper
            data-is-expand-controllable={isExpandControllable}
            expanded={isExpanded}
            ref={ref}
            onChange={(_e, expanded) => {
                if (expanded) {
                    open();
                } else {
                    close();
                }
            }}
            elevation={0}
            {...props}
        />
    );
});

const FAQItemTitleWrapper = styled(AccordionSummary)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize * 1.4,
    lineHeight: 1.4,
    gap: theme.spacing(2),
    padding: 0,
    minHeight: "unset",

    "&.Mui-expanded": {
        margin: 0,
        minHeight: "unset",
    },
    " > .MuiAccordionSummary-content, > .MuiAccordionSummary-content.Mui-expanded": {
        margin: 0,
    },
}));

export type FAQItemTitleProps = Omit<AccordionSummaryProps, "expandIcon" | "id">;

export const FAQItemTitle = forwardRef(function FAQItemTitle(
    props: FAQItemTitleProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    return <FAQItemTitleWrapper data-ref="faq-item-title" expandIcon={<ExpandMoreIcon />} ref={ref} {...props} />;
});

export const FAQItemContent = styled(AccordionDetails)({
    padding: 0,

    "> *:last-child": {
        marginBottom: 0,
    },
});
