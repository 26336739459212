import { styled } from "@mui/material/styles";
import { CSSProperties, ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { AssetUrl, assetUrlToVaultUrl, VaultUrl } from "@holibob-packages/vault";

import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export type HbmlVaultImageShape = "circle" | "original";
export const HbmlVaultImageShapeValues = ["circle", "original"] as const;

const Wrapper = styled("span")(({ theme }) => ({
    display: "inline-block",
    maxWidth: "100%",
    "&>img, svg": {
        display: "block",
        maxWidth: "100%",
    },
    '&[data-shape="circle"]': {
        borderRadius: "50%",
        overflow: "hidden",
        "& > img": {
            aspectRatio: "1",
            objectFit: "cover",
        },
    },
    '&[data-shape="original"]': {
        borderRadius: 5,
        overflow: "hidden",
    },
    '&[data-float="left"]': {
        margin: theme.spacing(2),
        marginLeft: 0,
    },
    '&[data-float="right"]': {
        margin: theme.spacing(2),
        marginRight: 0,
    },
    "&[data-float]": {
        marginTop: 0,
        [theme.breakpoints.down("sm")]: {
            float: "unset !important",
        },

        "&:after": {
            display: "block",
            content: '""',
            clear: "both",
        },
    },
    '&[data-has-width="false"]': {
        maxWidth: "100%",
    },
}));

export const HbmlVaultImageNode = hbmlNodeFactory<
    typeof HbmlElements.VAULT_IMAGE,
    {
        id?: string;
        width?: number;
        alt?: string;
        style?: Pick<CSSProperties, "float">;
        shape?: HbmlVaultImageShape;
    },
    Omit<HTMLAttributes<HTMLDivElement>, "content">
>({
    type: HbmlElements.VAULT_IMAGE,
    Component: forwardRef(function ComponentInternal({ node, children, ...props }, ref: ForwardedRef<HTMLDivElement>) {
        const { id, width, shape = "original", alt = "", style } = node;
        if (!id) {
            return null;
        }

        const src = getHbmlImageUrl(id, width);

        return (
            <HbmlVaultImageWrapper shape={shape} width={width} style={style} ref={ref} {...props}>
                <img
                    src={src}
                    alt={alt}
                    width={width}
                    height={shape === "circle" ? width : undefined}
                    decoding="async"
                />
                {children}
            </HbmlVaultImageWrapper>
        );
    }),
});

export function getHbmlImageUrl(id: string, width?: number) {
    const assetUrl = AssetUrl.enforceUrlForIdOrUrl(id, {
        type: "hbmlImage",
    }).unwrap();

    let vaultUrl = assetUrlToVaultUrl(assetUrl);

    if (width && vaultUrl instanceof VaultUrl) {
        vaultUrl = vaultUrl.modifyEdits({
            resize: {
                fit: "cover",
                width: width,
            },
        });
    }

    return vaultUrl.toString();
}

export type HbmlVaultImageComponentProps = Pick<
    (typeof HbmlVaultImageNode)["attributes"],
    "style" | "width" | "shape"
> &
    HTMLAttributes<HTMLDivElement>;
export const HbmlVaultImageWrapper = forwardRef(function HbmlVaultImageComponentInternal(
    { shape = "original", width, style, ...props }: HbmlVaultImageComponentProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const finalStyle: CSSProperties = {
        ...style,
    };

    if (width) {
        finalStyle.width = width + "px";
    }
    return (
        <Wrapper
            data-shape={shape}
            data-has-width={!!width}
            data-float={style?.float}
            ref={ref}
            style={finalStyle}
            {...props}
        />
    );
});
