import { ComponentType, HTMLAttributes, ReactNode } from "react";
import { ValueOf } from "ts-essentials";

import { HbmlElementNode, HbmlTextNode } from "@holibob-packages/hbml";

import { HeroImages } from "../HeroImages";
import { HeroVideo } from "../HeroVideo";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlHeroMediaType = {
    IMAGE: "IMAGE",
    VIDEO: "VIDEO",
} as const;

export type HbmlHeroMediaType = ValueOf<typeof HbmlHeroMediaType>;
export type HbmlHeroMode = "text" | "search";
export type HbmlHeroNodeInternalComponent = ComponentType<
    { children?: ReactNode } & (
        | { mediaType: typeof HbmlHeroMediaType.IMAGE; images: string[] }
        | { mediaType: typeof HbmlHeroMediaType.VIDEO; video?: string }
    )
>;

type HbmlHeroNodeAttributes = { mode?: HbmlHeroMode } & (
    | { mediaType: typeof HbmlHeroMediaType.IMAGE; images?: string[] }
    | { mediaType: typeof HbmlHeroMediaType.VIDEO; video?: string }
    | { mediaType?: HbmlHeroMediaType; images?: string[]; video?: string }
);

export const HbmlHeroNode = hbmlNodeFactory<
    typeof HbmlElements.HERO,
    HbmlHeroNodeAttributes,
    Omit<HTMLAttributes<HTMLDivElement>, "content"> & {
        Component?: HbmlHeroNodeInternalComponent;
        SearchComponent?: ComponentType<{ children: ReactNode }>;
    }
>({
    type: HbmlElements.HERO,
    Component({ node, Component: ComponentProp, SearchComponent, children, ...props }) {
        const mode = node.mode ?? "text";
        const finalChildren =
            mode === "text" ? children : SearchComponent ? <SearchComponent>{children}</SearchComponent> : null;
        if (node.mediaType === HbmlHeroMediaType.VIDEO) {
            const Component = ComponentProp ?? HeroVideo;
            return (
                <Component mediaType={HbmlHeroMediaType.VIDEO} video={node.video} {...props}>
                    {finalChildren}
                </Component>
            );
        } else {
            const children = node.children as HbmlElementNode[] | undefined;
            const heroTitle = children?.find((child) => child.type === "hero-title")?.children[0] as
                | HbmlTextNode
                | undefined;
            const Component = ComponentProp ?? HeroImages;
            return (
                <Component
                    mediaType={HbmlHeroMediaType.IMAGE}
                    alt={heroTitle?.text}
                    images={node.images ?? []}
                    {...props}
                >
                    {finalChildren}
                </Component>
            );
        }
    },
});
