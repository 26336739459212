import React, { CSSProperties, HTMLAttributes } from "react";

import { ThemeDefaultNamedColour } from "@holibob-packages/colour";

import { useColourResolver } from "../../hooks";
import { FontAwesomeIconRaw, RawIconDefinition } from "../FontAwesomeIconRaw";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

type DefaultIcon = { color: ThemeDefaultNamedColour; fontSize: number };
export const DEFAULT_ICON_STYLE: DefaultIcon = {
    color: "dark",
    fontSize: 32,
};

export const DEFAULT_ICON_COLOUR: ThemeDefaultNamedColour = "dark";
export const DEFAULT_ICON_FONT_SIZE = 32;

export type HbmlIconNodeAttributes = {
    // iconName and prefix are used to find the icon definition when enhancing the node
    iconName?: string;
    prefix?: string;
    enhanced?: { definition: RawIconDefinition };
    style?: Pick<CSSProperties, "color" | "fontSize">;
};

export const HbmlIconNode = hbmlNodeFactory<
    typeof HbmlElements.ICON,
    HbmlIconNodeAttributes,
    Omit<HTMLAttributes<HTMLButtonElement | HTMLAnchorElement>, "content">
>({
    type: HbmlElements.ICON,
    Component: function ComponentInternal({ node, children }) {
        const { enhanced, style } = node;

        const colourResolver = useColourResolver();

        if (!enhanced) return children;

        const finalStyle: CSSProperties = {
            fontSize: style?.fontSize ?? DEFAULT_ICON_STYLE.fontSize,
            color: colourResolver(style?.color ?? DEFAULT_ICON_STYLE.color),
        };

        return (
            <>
                <FontAwesomeIconRaw style={finalStyle} definition={enhanced.definition} />
                {children}
            </>
        );
    },
});
