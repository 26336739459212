import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";
import { useFavouriteButton } from "hooks/useFavouriteButton";

import { ProductCardV2, ProductCardV2Props } from "@holibob-packages/ui-core/product";

export const BookingProductCardV2 = (props: ProductCardV2Props) => {
    const {
        isAvailable: showFavouriteButton,
        isFavourite,
        setIsFavourite,
    } = useFavouriteButton(props.id, props.isFavourite ?? false);
    const newTab = useEntityConfigurationValueByKey<boolean>("SETTING.PRODUCT_CARD_NEW_TAB");
    const onFavouriteChange = async (val: boolean) => {
        await setIsFavourite(val);
        props.onFavouriteChange?.(val);
    };

    return (
        <ProductCardV2
            {...props}
            newTab={newTab}
            showFavouriteButton={showFavouriteButton}
            isFavourite={isFavourite}
            onFavouriteChange={onFavouriteChange}
        />
    );
};
