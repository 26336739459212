import { styled } from "@mui/material/styles";

import { srcToVaultUrl } from "../components";
import { HbmlChildrenWrapperOverlay } from "./HbmlComponents/common/HbmlChildrenWrapperOverlay";

export type HeroVideoProps = {
    height?: number;
    video?: string;
    videoConfig?: React.MediaHTMLAttributes<HTMLVideoElement>;
} & React.HTMLAttributes<HTMLDivElement>;

const DEFAULT_HEIGHT = 400;

const Video = styled("video")({
    objectFit: "cover",
    width: "100%",
});

const Wrapper = styled("div")({
    position: "relative",
    width: "100%",
    maxWidth: "100%",
});

export function HeroVideo(props: HeroVideoProps) {
    const { video, children, height = DEFAULT_HEIGHT, videoConfig, ...restProps } = props;
    const src = video ? srcToVaultUrl(video)?.toString() : undefined;

    return (
        <Wrapper data-testid="heroVideo" style={{ height }} {...restProps}>
            <Video style={{ height }} {...videoConfig}>
                {src && <source src={src} />}
            </Video>
            <HbmlChildrenWrapperOverlay>{children}</HbmlChildrenWrapperOverlay>
        </Wrapper>
    );
}
