import { CurationNewParams, urlCuration } from "@holibob-packages/url";

import { MarketPlaceTileA, MarketPlaceTileAProps } from "../components";

export type CurationCardWithProductCountProps = CurationNewParams & Omit<MarketPlaceTileAProps, "href">;

export function CurationCardWithProductCount(props: CurationCardWithProductCountProps) {
    const { curationId, slug, curationIdsPath, ...restProps } = props;
    const href = urlCuration({ curationId, slug, curationIdsPath });

    return <MarketPlaceTileA href={href} {...restProps} />;
}
