import { styled } from "@mui/material/styles";
import { ForwardedRef, forwardRef, HTMLAttributes } from "react";
import { ElementOf } from "ts-essentials";

export type StackColumns = ElementOf<typeof StackColumnsValues>;
export const StackColumnsValues = [1, 2, 3, 4] as const;

const Wrapper = styled("div")(({ theme }) => ({
    display: "grid",
    gap: theme.airiness,
    [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "repeat(var(--stack-columns-medium), minmax(0, 1fr))",
    },
    [theme.breakpoints.up("lg")]: {
        gridTemplateColumns: "repeat(var(--stack-columns), minmax(0, 1fr))",
    },
}));

export type StackProps = {
    columns?: StackColumns;
} & HTMLAttributes<HTMLDivElement>;

export const Stack = forwardRef(function StackInternal(
    { columns = 2, style, ...props }: StackProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const finalStyle = { ...style, "--stack-columns": columns, "--stack-columns-medium": columns > 2 ? 2 : columns };
    return <Wrapper style={finalStyle} {...props} ref={ref} />;
});

export const StackItem = styled("div")(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    boxSizing: "border-box",
}));
