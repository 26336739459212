import TextField, { TextFieldProps } from "@mui/material/TextField";
import React from "react";

export type TextInputProps = TextFieldProps & {
    readOnly?: boolean;
    onTextChange?: TextFieldProps["onChange"];
};
export function TextInput({
    InputProps: InputPropsProp = {},
    readOnly,
    onTextChange,
    onChange,
    ...props
}: TextInputProps) {
    const InputProps = {
        ...InputPropsProp,
        readOnly,
    };

    return <TextField InputProps={InputProps} onChange={onTextChange ?? onChange} {...props} />;
}

export default TextInput;
